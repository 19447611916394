import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}
var projection = [];

export default class AdminList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberBalance: [],
            tokenValue: [],
            ercLoading: false
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
        this.getUserBalance();
        this.getMemberBalance();
    }

    getUserBalance = async () => {
        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data

                this.setState({
                    tokenValue: res.data.data,
                    ercLoading: true
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }

    getMemberBalance = async () => {
        await axios.get(API_URL.GET_ALL_BALANCE)
            .then(res => {
                console.log("Member Balance: ", res.data.data);
                let userData = res.data.data
                let value = Object.keys(userData[0])
                projection.push(value)
                console.log("Projection: ", projection);
                this.setState({ memberBalance: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                let data = JSON.stringify(row)
                                console.log("Row member:", row);
                                localStorage.setItem('memberBalance', data)
                                this.props.history.push("/viewmember")
                            }}>
                            <i className="fa fa-eye " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        console.log("Row: ", row);

        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="col-md-12">
                    <a href=""
                    // onClick={() => {
                    //     let data = row.userId
                    //     console.log("Withdraw", row.userId);
                    //     localStorage.setItem('emailData', data)
                    //     this.props.history.push("/listuseredit")
                    // }}
                    >
                        <p style={{ color: '#0069D9' }}></p>
                    </a>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Member Balance</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Member Balance
                                                </CardHeader>
                                                <CardBody >
                                                    <div style={{ overflowX: 'scrollable' }}>
                                                        {/* {this.state.ercLoading === true &&
                                                            <BootstrapTable data={this.state.memberBalance} keyField='BTC' pagination
                                                                tableStyle={{ fontSize: 12, textAlign: 'center' }}
                                                                search>
                                                                {this.state.tokenValue.map((d, xid) => (<>
                                                                    {xid == 0 &&
                                                                    <TableHeaderColumn isKey={true} dataField='email'  thStyle={headerStyle}>EMAIL</TableHeaderColumn>
                                                                     } 
                                                                    <TableHeaderColumn dataField='BTC' thStyle={headerStyle}>{d.tokenSymbol}</TableHeaderColumn>
                                                                 
                                                                </>
                                                                ))}

                                                            </BootstrapTable>
                                                        } */}
                                                        <BootstrapTable data={this.state.memberBalance} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}
                                                            search
                                                        // exportCSV
                                                        >
                                                            <TableHeaderColumn width={'31%'} isKey dataField='email' thStyle={headerStyle} tdStyle={{ whiteSpace: 'normal' }}>EMAIL</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='BTC' width={'15%'} thStyle={headerStyle} thStyle={headerStyle} tdStyle={{ whiteSpace: 'normal' }}>BTC</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='ETH' width={'15%'} thStyle={headerStyle} thStyle={headerStyle} tdStyle={{ whiteSpace: 'normal' }}>ETH</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='XRP' width={'15%'} thStyle={headerStyle}>XRP</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='LTC' width={'15%'} thStyle={headerStyle}>LTC</TableHeaderColumn> */}
                                                            {/* <TableHeaderColumn dataField='DASH' width={'15%'} thStyle={headerStyle}>DASH</TableHeaderColumn> */}
                                                            {/* <TableHeaderColumn dataField='XLM' width={'15%'} thStyle={headerStyle}>XLM</TableHeaderColumn> */}
                                                            <TableHeaderColumn dataField='TRX' width={'15%'} thStyle={headerStyle}>TRX</TableHeaderColumn>
                                                            {/* <TableHeaderColumn dataField='BCH' width={'15%'} thStyle={headerStyle}>BCH</TableHeaderColumn> */}
                                                            <TableHeaderColumn dataField='DOGE' width={'15%'} thStyle={headerStyle}>DOGE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='USDT' width={'15%'} thStyle={headerStyle}>USDT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='BALLX' width={'15%'} thStyle={headerStyle}>BALLX</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                width={'15%'}
                                                                tdStyle={{ whiteSpace: 'normal' }}
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                            >ACTION</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </div>

                                                    {/* <BootstrapTable data={this.state.memberBalance} pagination
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}
                                                        search>
                                                        <TableHeaderColumn isKey dataField='email'  thStyle={headerStyle}>EMAIL</TableHeaderColumn>
                                                        {this.state.tokenValue.map((d) => (
                                                             <TableHeaderColumn dataField='BTC' thStyle={headerStyle}>{d.tokenSymbol}</TableHeaderColumn>
                                                        ))}
                                                        <TableHeaderColumn dataField='createdOn' width={'27%'} thStyle={headerStyle}>DATE OF JOINING</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            width={'15%'}
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable> */}
                                                    {/* <table className="table table-hover table-bordered">
                                                        <thead>
                                                        {
                                                            projection.map((d) => (
                                                            <tr>
                                                                <th colspan="2">{d}</th>
                                                            </tr>
                                                            ))
                                                        }
                                                            
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>The table body</td>
                                                                <td>with two columns</td>
                                                            </tr>
                                                        </tbody>
                                                    </table> */}
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
