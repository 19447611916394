import React from 'react'

function WorkInProgress() {
  return (
//    <div style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh"}}>
     <div style={{backgroundImage: `url("/images/work-in-progress.png")`, height:"100vh", backgroundSize:"contain"}}>

</div>
//    </div>
  )
}

export default WorkInProgress