export const BASE_URL = "https://adminapi.bitallx.com/";
//  export const BASE_URL = "http://localhost:5000/";

export const API_URL = {
   USER_INFORMATION : BASE_URL+'userInformation/get/all',
   UPDATE_USER : BASE_URL+'userInformation/update',
   USER_BY_ID : BASE_URL+'userInformation/get',
   USER_BY_ID_ADMIN : BASE_URL+'userInformationForAdmin/get',
   USER_COUNT : BASE_URL+'userInformation/count',
   DEPOSITE_GET : BASE_URL+'depositehistory/get/all',
   WITHDRAW_GET : BASE_URL+'withdrawal/get/all',
   USER_EMAIL : BASE_URL+'userInformation/get/email',
   WITHDRAW_STATUS_UPDATE :  BASE_URL+'withdrawal/update/status',
   ADD_BALANCE:BASE_URL+'userbalance/updatebalancebywalletcode',
   
   ERC20_TOKEN_LIST : BASE_URL+'ercToken/get/all1',
   ERC20_TOKEN_LIST_ALL : BASE_URL+'ercToken/get/all',
   ADD_TOKEN : BASE_URL+'ercToken/save',
   TOKEN_UPDATE : BASE_URL+'ercToken/update',
   DELETE_TOKEN : BASE_URL+'ercToken/delete',
   TOKEN_COUNT :BASE_URL+'ercToken/count',
   TOKEN_UPLAOD_ICON :BASE_URL+'tokenimage/upload',
   
   USER_BALANCE : BASE_URL+'userBalance/get',
   GET_ALL_BALANCE: BASE_URL+ 'userBalance/get/all',
   DEPOSITE_HISTORY : BASE_URL+'depositehistory/get/user',
   WITHDRAW_HISTORY : BASE_URL+'withdrawal/get/user',

   ADMIN_ADD: BASE_URL+'admin/add',
   ADMIN_LOGIN: BASE_URL+'admin/login',
   ADMIN_LIST : BASE_URL+'admin/get/all',
   ADMIN_GET_ID: BASE_URL+'admin/get',
   UPDATE_ADMIN: BASE_URL+'admin/update',
   DELETE_ADMIN: BASE_URL+'admin/delete',
   PASSWORD_CHANGE: BASE_URL+'changePassword/admin',
   INRBANK: BASE_URL+'addbankac',
   GETINRBANK: BASE_URL+'getbankac',

   ACTIVITY_LOG: BASE_URL+'loginhistory/get',

   ANNOUNCEMENT_ADD: BASE_URL+'announcement/add',
   ANNOUNCEMENT_GET_ALL: BASE_URL+'announcement/getall',
   ANNOUNCEMENT_DELETE: BASE_URL+'announcement/delete',
   UPDATE_ANNOUNCE: BASE_URL+'announcement/update',

   FILE_UPLOAD: BASE_URL+'uploads/file',

   KYC_LIST : BASE_URL+'kyc/get/all',
   KYC_UPDATE: BASE_URL+'kyc/update',
   KYC_COUNT: BASE_URL+'kyc/count',
   
   ORDER_GET: BASE_URL+'order/get/all',

   TRNSFER_DEPOSIT_FUNDS : BASE_URL + 'depositehistory/updatesendstatus',
   
   GET_STAKING_MASTER : BASE_URL + 'stakingMaster/get/all',
   ADD_STACKE : BASE_URL + 'stakingMaster/addStaking',
   UPDATE_STAKE : BASE_URL + 'stakingMaster/updateStaking',
   USER_STAKE : BASE_URL + 'userStaking/getStakingByStatus',
   GET_STAKE_level : BASE_URL + 'Staking/getStakinglevel',

   BANKTRANSFERS_GET : BASE_URL+'banktransfer/get/all',
   BANKTRANSFERS_STATUS_UPDATE :  BASE_URL+'bankTransfer/update/status',

   ADD_COIN_PAIR : BASE_URL + 'coinPair/add',
   UPDATE_COIN_PAIR : BASE_URL + "coinPair/update",
   GETALL_COIN_PAIRS : BASE_URL + "coinPair/get/all",

   ADD_FUTURE_COIN_PAIR : BASE_URL + 'futureCoinPair/add',
   UPDATE_FUTURE_COIN_PAIR : BASE_URL + "futureCoinPair/update",
   GETALL_FUTURE_COIN_PAIRS : BASE_URL + "futureCoinPair/get/all",
};

