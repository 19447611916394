import Axios from 'axios';
import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import axios from "axios";
import { API_URL } from '../../config/api';
import { Link } from 'react-router-dom';

// const products = [];

// function addProducts(quantity) {
//   const startId = products.length;
//   for (let i = 0; i < quantity; i++) {
//     const id = startId + i;
//     if (i < 3) {
//       products.push({
//         id: id,
//         name: 'Item name ' + id,
//         price: 2100 + i,
//         expand: [{
//           fieldA: 'test1',
//           fieldB: (i + 1) * 99,
//           fieldC: (i + 1) * Math.random() * 100,
//           fieldD: '123eedd' + i
//         }, {
//           fieldA: 'test2',
//           fieldB: i * 99,
//           fieldC: i * Math.random() * 100,
//           fieldD: '123eedd' + i
//         }]
//       });
//     } else {
//       products.push({
//         id: id,
//         name: 'Item name ' + id,
//         price: 2100 + i
//       });
//     }
//   }
// }
// addProducts(5);
// var history;
// const idCell = (cell, row, enumObject, rowIndex)=> {
//   return (
//       <div style={{ flex: 1, alignItems: 'center', width: '100%' }}>
//           <div class="col-md-12">
//               <a href=""
//                   onClick={() => {
//                     localStorage.setItem('emailData', row.childId)
//                     history?.push("/edituser")
//                   }}
//                   >
              
//                   <p style={{ color: '#0069D9' }}>{row.childId}
//                     {/* <Link to='edituser' >{row.childId}</Link> */}
//                     </p>
//               </a>
//           </div>
//       </div>
//   )
// }
class BSTable extends React.Component {
  idCell = (cell, row, enumObject, rowIndex)=> {
    return (
        <div style={{ flex: 1, alignItems: 'center', width: '100%' }}>
            <div class="col-md-12">
                <a href=""
                    onClick={() => {
                      localStorage.setItem('emailData', row.childId)
                      // this.props.history.push("/edituser")
                    }}
                    >
                
                    <p style={{ color: '#0069D9' }}>{row.childId}
                      {/* <Link to='edituser' >{row.childId}</Link> */}
                      </p>
                </a>
            </div>
        </div>
    )
  }
  render() {
    if (this.props.data) {
      console.log(this.props.data)
      return (
        <BootstrapTable data={this.props.data}>
          <TableHeaderColumn dataField='childId' dataFormat={this.idCell} isKey={true}>Level</TableHeaderColumn>
          {/* <TableHeaderColumn dataField='childId' isKey={true}>USER ID</TableHeaderColumn> */}
        </BootstrapTable>);
    } else {
      return (<p>?</p>);
    }
  }
}

export default class ReferalTable extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      levelData:[]
    }
    
  }

  isExpandableRow(row) {
    if (row.expand ) return true;
    else return false;
  }

  expandComponent(row) {
    console.log(row)
    return (
      <>
      <BSTable data={row.expand} />
                </>
    );
  }
componentDidMount(){
  // history=  this.props.history
  this.getStakeLevels()
}
getStakeLevels = async()=>{
  let login = localStorage.getItem('loginData');
const user = login?JSON.parse(login):null;
if (!user) {
  return;
}
  await axios.post(API_URL.GET_STAKE_level,{userId:this.props.match.params.userId,adminuserId:user._id})
  .then(res => {
if (res && res.data && res.data.data) {
  let data=[];
  res.data.data.forEach(e0 => {
    if (e0.length > 0) {
      
      data.push({childId: e0[0].childId,expand:e0})
    } else {
      data.push({childId: e0[0].childId})
    }
  });
  console.log('LEVEl_data',data)
  this.setState({levelData:data})
}
  })
}
  // makeLevel=(d)=>{
  //   let data;
  //   d.forEach(e1 => {
  //     if (e1.length>0) {
  //       const tdata= this.makeLevel();
  //       data ={id:Date.now(),expand:tdata}
  //     }else{
  //     data = {id:Date.now(),expand:e1}
  //     }
  //   });
  //   return data
  // }
  idCell = (cell, row, enumObject, rowIndex)=> {
    return (
        <div style={{ flex: 1, alignItems: 'center', width: '100%' }}>
            <div class="col-md-12">
                <a href=""
                    onClick={() => {
                      localStorage.setItem('emailData', row.childId)
                      // this.props.history.push("/edituser")
                    }}
                    >
                
                    <p style={{ color: '#0069D9' }}>{row.childId}
                      {/* <Link to='edituser' >{row.childId}</Link> */}
                      </p>
                </a>
            </div>
        </div>
    )
  }
  render() {
    // const options = {
    //   expandRowBgColor: 'rgb(242, 255, 163)'
    // };
    return (
      <>
        <Header />
        <Menu />
        <div className="wrapper">
          <div className="content-wrapper" style={{ marginTop: '0px' }}>
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">User Stake</h1>
                  </div>{/* /.col */}
                  {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                </div>{/* /.row */}
              </div>
            </div>
            <section className="content">
              <div className="container-fluid">
                <div className="col-md-12">

                  <BootstrapTable data={this.state.levelData}
                    // options={options}
                    expandableRow={this.isExpandableRow}
                    expandComponent={this.expandComponent}
                    expandColumnOptions={{ expandColumnVisible: true }}>
                    <TableHeaderColumn dataField='childId'dataFormat={this.idCell} isKey={true}>Level</TableHeaderColumn>
                    {/* <TableHeaderColumn dataField='childId'>USER ID</TableHeaderColumn> */}
                  </BootstrapTable>
                </div>
              </div>
            </section>
          </div>
          </div>
        </>
        );
  }
}