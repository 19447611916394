import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Input, Button } from 'reactstrap';
import { API_URL } from "../../config/api";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import Spinner from 'react-bootstrap/Spinner';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';


const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const input = {
    fontSize: '13px',
    color: '#333'
}




export default class AddCoinPairs extends Component {
    constructor(props){
        super(props);
        this.state = {
            tokenValue: [],
            allCoinPairsList : [],
            // buyData: [],
            // sellData: [],
            // coinName: 'ETH',
            // pair: 'USDT',
            loader : 1,

            primaryCoin : '',
            secondaryCoin : '',
            price : '',
            priceDecimal : '',
            amountDecimal : '',
            decimal : '',
            isBinance : false,

            editPrimaryCoin : '',
            editSecondaryCoin : '',
            editPrice : '',
            editPriceDecimal : '',
            editAmountDecimal : '',
            editDecimal : '',
            editIsBinance : false,
            editCoinId : '',
            active:true,
           
           
           

            addOpenModal : false,
            updateOpenModal : false,
        }
    }

    

   async componentDidMount() {
    let login = localStorage.getItem('adminLogin', '')
    console.log(login);
    if (login !== '1') {
      this.props.history.push('/');
    }
        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({ 
                    tokenValue: res.data.data,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        this.getAllCoinPairs();
    }

    getAllCoinPairs = async () => {
        await axios.get(API_URL.GETALL_COIN_PAIRS)
        .then(res => {
            console.log("GETALL_COIN_PAIRS:", res.data.data);
            let data = res.data.data
            this.setState({ 
                allCoinPairsList: res.data.data,
            })
            console.log(this.state)
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    }

    open = async(e) => {
        e.preventDefault()
        this.setState({addOpenModal : true})
    }

    add = async() => {
        let data =  {
            "primaryCoin" : this.state.primaryCoin, 
            "secondaryCoin" : this.state.secondaryCoin,
            "price" : this.state.price,
            "priceDecimal" : this.state.priceDecimal,       
            "amountDecimal" : this.state.amountDecimal,
            "decimal" : this.state.decimal,
            "isBinance" : this.state.isBinance,
            "active" : this.state.active
        }
        console.log(data)

        if(this.state.primaryCoin != this.state.secondaryCoin ){
        this.setState({ 
            loader: 0
        })
       
        console.log("add coin pair data  ", data)
        await axios.post(API_URL.ADD_COIN_PAIR, data)
        .then(res => {
            console.log("add coin pair response:", res.data);
            if(res.data.statusCode == 200){
                toast.success(res.data.message)
                this.getAllCoinPairs();
                this.setState({
                    addOpenModal : false,
                    priceDecimal : '',
                    amountDecimal : '',
                    decimal : '',
                    isBinance  : false,
                    primaryCoin : '',
                    secondaryCoin : '',
                    price : ''
                })

            }else{
                toast.error(res.data.message)
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    } else{
            toast.warning("Primary & Secondary Coins are same")
    }
    }

    onCloseModal_add = ()=>{
        this.setState({addOpenModal : false})
}
onCloseModal_update = ()=>{
    this.setState({updateOpenModal : false})
}

    updateCoinPairs = async()=>{
        let data = {
            "id" : this.state.editCoinId,
            "primaryCoin" : this.state.editPrimaryCoin, 
            "secondaryCoin" : this.state.editSecondaryCoin,
            "price" : this.state.editPrice,
            "priceDecimal" : this.state.editPriceDecimal,       
            "amountDecimal" : this.state.editAmountDecimal,
            "decimal" : this.state.editDecimal,
            "isBinance" : this.state.editIsBinance,
            "active" : this.state.active
        }
        console.log(data)
        axios.post(API_URL.UPDATE_COIN_PAIR, data)
        .then(res => {
            console.log("update coin pair response:", res.data);
            if(res.data.statusCode == 200){
                toast.success(res.data.message)
                this.setState({
                    updateOpenModal : false
                })
                this.getAllCoinPairs();
                
            }else{
                toast.error(res.data.message)
            }
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    }
    editCoin = async(row )=>{
        console.log(row)
        this.setState({
           
            editPrimaryCoin : row.primaryCoin,
            editSecondaryCoin : row.secondaryCoin,
            editPrice : row.price,
            editPriceDecimal : row.priceDecimal,
            editAmountDecimal : row.amountDecimal,
            editDecimal : row.decimal,
            editIsBinance : row.isBinance,
            editCoinId : row._id,
            active: row.active
        })
       
    }





    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    statusFormatter(cell, row, enumObject, rowIndex){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div>{row.isDeleted ? 'Inactive': 'Active'}</div>
            </div>
        )
    }
    // coinpair(cell, row, enumObject, rowIndex) {
    //     console.log("row value: ", row);
    //     return (
    //         <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
    //             <div class="row">
    //                 <div class="col-md-12">
    //                     <a href="#" 
    //                         // onClick={() => {
    //                         //     console.log("Cell value: ", row)
    //                         //     this.deleteUser(row)
    //                         // }}
    //                         >
    //                         {row.coinName} / {row.pair}
    //                     </a>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        // if(row.status === 1){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                <div class="row">
                    <div class="col-md-12">
                    <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={(e) => {
                                e.preventDefault()
                                this.setState({updateOpenModal : true})
                                // localStorage.setItem('addCoinPairsData', JSON.stringify(row))
                                console.log("To worriers",row)
                                this.editCoin(row)
                            }}>
                            <i className="fa fa-edit " />
                    </a>
                    </div>
                </div>
            </div>
        )
    }

    binancebuttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if(row.isBinance === true){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                <div class="row">
                    <div class="col-md-12">
                    <a href="#" > &#9989;	    </a>
                    </div>
                </div>
            </div>
        )
        }else{
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                    <div class="row">
                        <div class="col-md-12">
                        <a href="#" > &#10060;    </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <ToastContainer />
                <Menu />

          

                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>

                        
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Spot Coin Pairs</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                            {/* <div className="col-md-12" style={{ marginLeft: '10px', paddingTop: '10px' }}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <p style={{ color: "#777" }}>Primary Coins</p>
                                    </div>
                                    <h1></h1>
                                    <div className="col-md-4" >
                                        <p style={{ color: "#777" }}>Secondary Coin</p>
                                    </div>
                                    <div className="col-md-2" >
                                        <p style={{ color: "#777" }}>Price</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                <div className="d-flex" style={{justifyContent:'end'}}>
                                    {/* <div className="col-md-4">
                                        <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    primaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Primary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}

                                        </select>
                                    </div> */}

                                    
                                    {/* <div className="col-md-4">
                                    <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    secondaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Secondary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}
                                        </select>
                                    </div> */}
                                    {/* <div className="col-md-2">
                                    <Input
                                                            type="number"
                                                            name="margin_limit"
                                                            id="margin_limit_"
                                                            style={input}
                                                            value={this.state.price}
                                                            placeholder="Price"
                                                            onChange={e => {
                                                                this.setState({
                                                                    price: e.target.value
                                                                })
                                                            }} />
                                    </div> */}


                                    <div style={{marginRight: 16}}>
                                    <Button  color="primary" onClick={this.open} >Add Coin Pair</Button>
                                    </div>
                                </div>
                            </div>
                        </div>


                        
                <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                {/* <CardHeader>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <i className="fa fa-align-justify" style={{
                                                                marginRight: 10
                                                            }}></i>
                                                    </div>
                                                        <div className="col-md-6">
                                                            <Button color="primary" style={{ float: 'right' }}
                                                                onClick={(e) => {
                                                                    this.props.history.push('/addRole')
                                                                }}>All Coins Pairs</Button>
                                                        </div>
                                                    </div>
                                                </CardHeader> */}
                                                <CardBody>

                                                <BootstrapTable data={this.state.allCoinPairsList} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'10%'}>No.</TableHeaderColumn>
                                                        <TableHeaderColumn 
                                                            dataField='primaryCoin' 
                                                            width={'20%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Primary Coin</TableHeaderColumn>
                                                             <TableHeaderColumn 
                                                            dataField='secondaryCoin' 
                                                            width={'30%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Secondary Coin</TableHeaderColumn>
                                                             {/* <TableHeaderColumn 
                                                            dataField='price' 
                                                            width={'20%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Price</TableHeaderColumn>
                                                               <TableHeaderColumn 
                                                            dataField='priceDecimal' 
                                                            width={'20%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Price Decimal</TableHeaderColumn>
                                                             <TableHeaderColumn 
                                                            dataField='amountDecimal' 
                                                            width={'20%'} 
                                                            dataSort={true} 
                                                            thStyle={headerStyle}>Amount Decimal</TableHeaderColumn>

                                                        <TableHeaderColumn
                                                            dataField='decimal'
                                                            width={'20%'}
                                                            dataSort={true}
                                                            thStyle={headerStyle}> Decimal</TableHeaderColumn> */}

                                                        <TableHeaderColumn dataField='isDeleted'  dataFormat={this.statusFormatter.bind(this)} thStyle={headerStyle} isKey >STATUS</TableHeaderColumn>
                                                       
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.binancebuttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        > Binance</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>

                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                <Modal open={this.state.addOpenModal} onClose={this.onCloseModal_add} >
                    <div className='row'>
                        <div className='col-md-12 text-center'>
                            <h3>Add Coins Pairs</h3>
                        </div>


                        {/* <div className='col-md-12 mt-3 mb-2 '>
                            <p>Price Decimal</p>
                            <Input
                                type="number"
                                name="price_decimal"
                                id="price_decimal_"
                                style={input}
                                value={this.state.priceDecimal}
                                placeholder="Price Decimal"
                                onChange={e => {
                                    this.setState({
                                        priceDecimal: e.target.value
                                    })
                                }} />
                        </div>

                        <div className='col-md-12 mt-2 mb-2 '>
                            <p>Amount Decimal</p>
                            <Input
                                type="number"
                                name="amount_decimal"
                                id="amount_decimal_"
                                style={input}
                                value={this.state.amountDecimal}
                                placeholder="Amount Decimal"
                                onChange={e => {
                                    this.setState({
                                        amountDecimal: e.target.value
                                    })
                                }} />
                        </div>

                        <div className='col-md-12 mt-2 mb-2 '>
                            <p> Decimal</p>
                            <Input
                                type="number"
                                name="decimal__"
                                id="decimal___"
                                style={input}
                                value={this.state.decimal}
                                placeholder="Decimal"
                                onChange={e => {
                                    this.setState({
                                        decimal: e.target.value
                                    })
                                }} />
                        </div> */}
                        <div className='col-md-12 mt-2 mb-2 '>
                        <p style={{ color: "#777" }}>Primary Coins</p>
                            <select
                                className="form-control editor edit-text"
                                style={input}
                                // value={this.state.role}
                                onChange={e => {
                                    console.log("Coin: ", e.target.value);
                                    this.setState({
                                        primaryCoin: e.target.value,
                                    })
                                }}>
                                <option >Select Primary coin</option>
                                {this.state.tokenValue.map((d) => (
                                    d.active == true && <>
                                        <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                    </>
                                ))}

                            </select>
                        </div>
                         <div className='col-md-12 mt-2 mb-2 '>
                         <p style={{ color: "#777" }}>Secondary Coins</p>
                         <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    secondaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Secondary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}
                                        </select>
                         </div>
                        <div className='col-md-6 mt-2 mb-2 '>
                            <p>Binance</p>

                        </div>
                        <div className='col-md-6 mt-2 mb-2 '>

                            <input type="checkbox"
                                value={this.state.isBinance}
                                onChange={(e) => {

                                    this.setState({
                                        isBinance: e.target.checked,
                                    })

                                }} />
                        </div>

                        <div className='col-md-6 mt-2 mb-2 '>
                            <p>Active</p>

                        </div>
                        <div className='col-md-6 mt-2 mb-2 '>

                            <input type="checkbox"
                                checked={this.state.active}
                                onChange={(e) => {

                                    this.setState({
                                        active: e.target.checked,
                                    })

                                }} />
                        </div>
                        <div className='col-md-12 mt-2 mb-2 text-center'>
                            <Button class="primary" onClick={this.add} disabled={this.state.primaryCoin == '' || this.state.secondaryCoin == ''}> Add Coin Pair</Button>
                        </div>

                    </div>
                </Modal>

                <Modal open={this.state.updateOpenModal} onClose={this.onCloseModal_update} >
                                
                <div className='row'>
                        <div className='col-md-12 text-center'>
                            <h3>Edit Coin Pair</h3>
                        </div>

                        <div className='col-md-12 mt-2 mb-1 '>
                            <p>Primary Coins </p>
                            <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            value={this.state.editPrimaryCoin}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    editPrimaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Primary coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}

                                        </select>

                            </div>

                            <div className='col-md-12 mt-2 mb-2 '>
                            <p>Secondary Coins </p>
                            <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            value={this.state.editSecondaryCoin}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    editSecondaryCoin: e.target.value,
                                                })
                                            }}>
                                                <option >Select Secondary coin</option>
                                                <option value={'USDT'}>{'USDT'}</option>
                                                {/* {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))} */}

                                        </select>

                            </div>

                            {/* <div className='col-md-12 mt-2 mb-2 '>
                            <p>Price</p>
                            <Input
                                type="number"
                                name="editamount_decimal"
                                id="editamount_decimal_"
                                style={input}
                                value={this.state.editPrice}
                                placeholder="Price"
                                onChange={e => {
                                    this.setState({
                                        editPrice: e.target.value
                                    })
                                }} />
                        </div> */}
                        
                        {/* <div className='col-md-12 mt-2 mb-2 '>
                            <p>Price Decimal</p>
                            <Input
                                type="number"
                                name="edit_price_decimal"
                                id="edit_price_decimal_"
                                style={input}
                                value={this.state.editPriceDecimal}
                                placeholder="Price Decimal"
                                onChange={e => {
                                    this.setState({
                                        editPriceDecimal: e.target.value
                                    })
                                }} />
                        </div> */}

                        {/* <div className='col-md-12 mt-2 mb-2 '>
                            <p>Amount Decimal</p>
                            <Input
                                type="number"
                                name="editamount_decimal"
                                id="editamount_decimal_"
                                style={input}
                                value={this.state.editAmountDecimal}
                                placeholder="Amount Decimal"
                                onChange={e => {
                                    this.setState({
                                        editAmountDecimal: e.target.value
                                    })
                                }} />
                        </div> */}

                        {/* <div className='col-md-12 mt-2 mb-2 '>
                            <p> Decimal</p>
                            <Input
                                type="number"
                                name="decimal__edit"
                                id="decimal___edit"
                                style={input}
                                value={this.state.editDecimal}
                                placeholder="Decimal"
                                onChange={e => {
                                    this.setState({
                                        editDecimal: e.target.value
                                    })
                                }} />
                        </div> */}

                        <div className='col-md-6 mt-2 mb-2 '>
                            <p>Binance</p>

                        </div>
                        <div className='col-md-6 mt-2 mb-2 '>

                            <input type="checkbox" defaultChecked={this.state.editIsBinance}
                                checked={this.state.editIsBinance}
                                onChange={(e) => {

                                    this.setState({
                                        editIsBinance: e.target.checked,
                                    })

                                }} />
                        </div>
                        <div className='col-md-6 mt-2 mb-2 '>
                            <p>Active</p>

                        </div>
                        <div className='col-md-6 mt-2 mb-2 '>

                            <input type="checkbox"
                                checked={this.state.active}
                                onChange={(e) => {

                                    this.setState({
                                        active: e.target.checked,
                                    })

                                }} />
                        </div>

                        <div className='col-md-12 mt-2 mb-2 text-center'>
                            <Button class="primary" onClick={this.updateCoinPairs} disabled={this.state.editPrimaryCoin == '' || this.state.editSecondaryCoin == ''}> Update Coin Pair</Button>
                        </div>

                    </div>            

                </Modal>


            </div>
        );
    }
}
