import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row, Input, Button } from 'reactstrap';
import { API_URL } from "../config/api";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import Spinner from 'react-bootstrap/Spinner';

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class Transactions extends Component {
    constructor(props){
        super(props);
        this.state = {
            tokenValue: [],
            buyData: [],
            sellData: [],
            coinName: '',
            pair: '',
            loader : 1
        }
    }

   async componentDidMount() {
    let login = localStorage.getItem('adminLogin', '')
    console.log(login);
    if (login !== '1') {
      this.props.history.push('/');
    }
        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({ 
                    tokenValue: res.data.data,
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        this.getOrderData();
    }

    getOrderData = async() => {
        this.setState({ 
            loader: 0
        })
        let data = {
            "coinName": this.state.coinName,
            "pair" : this.state.pair
        }

        await axios.post(API_URL.ORDER_GET, data)
        .then(res => {
            console.log("User update:", res.data.data);
            let allData = res.data.data
            var buyData = allData.filter((d) => {
                return d.orderType === "Buy"
            })
            var sellData =  allData.filter((d) => {
                return d.orderType === "Sell"
            })
            this.setState({ 
                buyData: buyData,
                sellData: sellData,
                loader: 0
            })
        })
        .catch(error => {
            console.log("Error: ", error);
        })
    }

    coinpair(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#" 
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            {row.coinName} / {row.pair}
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if(row.status === 0){
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#" className="btn btn-block btn-warning btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Pending
                        </a>
                    </div>
                </div>
            </div>
        )
        } else if (row.status == 1) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-primary btn-xs"
                                // onClick={() => {
                                //     console.log("Cell value: ", row)
                                //     this.deleteUser(row)
                                // }}
                                >
                                Filled
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == 2) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                                // onClick={() => {
                                //     console.log("Cell value: ", row)
                                //     this.deleteUser(row)
                                // }}
                                >
                                Cancled
                            </a>
                        </div>
                    </div>
                </div>
            )
        }else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%'}}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                                // onClick={() => {
                                //     console.log("Cell value: ", row)
                                //     this.deleteUser(row)
                                // }}
                                >
                                Partially Filled
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Transactions List</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '10px', paddingTop: '10px' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <p style={{ color: "#777" }}>Coin</p>
                                    </div>
                                    <h1></h1>
                                    <div className="col-md-3" style={{ marginLeft: '7px' }}>
                                        <p style={{ color: "#777" }}>Pair</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12" style={{ marginLeft: '8px' }}>
                                <div className="row">
                                    <div className="col-md-3">
                                        {/* <Input placeholder="coin" /> */}
                                        <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Coin: ", e.target.value);
                                                this.setState({
                                                    coinName: e.target.value,
                                                })
                                            }}>
                                                <option >Select coin</option>
                                                {this.state.tokenValue.map((d) => ( 
                                                d.active == true && <>
                                                     <option value={d.tokenSymbol}>{d.tokenSymbol}</option>
                                                     </>
                                                ))}
                                            {/* <option style={{ color: '#999999' }}>--Select--</option> */}
                                           
                                            {/* <option value="Sub Admin">Sub Admin</option> */}
                                        </select>
                                    </div>

                                    <h1>/</h1>
                                    <div className="col-md-3">
                                    <select
                                            className="form-control editor edit-text"
                                            style={input}
                                            // value={this.state.role}
                                            onChange={e => {
                                                console.log("Pair: ", e.target.value);
                                                this.setState({
                                                    pair: e.target.value,
                                                })
                                            }}>
                                            <option >Select pair</option>
                                            <option value="BTC">BTC</option>
                                            <option value="ETH">ETH</option>
                                            <option value="USDT">USDT</option>
                                        </select>
                                    </div>
                                    <Button color="primary" onClick={this.getOrderData}>View Transactions</Button>
                                </div>
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader style={{ fontSize: '22px' }}>
                                                    <p style={{
                                                        marginRight: 10, fontSize: '18px'
                                                    }}></p>  Current Pair : {this.state.coinName} / {this.state.pair}
                                                </CardHeader>
                                                <CardBody >
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Transaction - Buy Coin</Tab>
                                                        <Tab>Transaction - Sell Coin</Tab>
                                                    </TabList>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        {this.state.loader === 1 ? 
                                                        <div style={{textAlign: 'center', justifyContent: 'center'}}>
                                                             <Spinner animation="border" variant="primary" />
                                                        </div>
                                                        :
                                                        <BootstrapTable data={this.state.buyData} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn isKey dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='price' width={'10%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='total' width={'10%'} thStyle={headerStyle}>TOTAL</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.coinpair.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='orderType' width={'10%'} thStyle={headerStyle}>TYPE</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                             <TableHeaderColumn dataField='dateTime' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                        </BootstrapTable>
                                                        }
                                                    </TabPanel>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                    {this.state.loader === 1 ? 
                                                        <div style={{textAlign: 'center', justifyContent: 'center'}}>
                                                             <Spinner animation="border" variant="primary" />
                                                        </div>
                                                        :
                                                        <BootstrapTable data={this.state.sellData} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn isKey dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='price' width={'10%'} thStyle={headerStyle}>PRICE</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='total' width={'10%'} thStyle={headerStyle}>TOTAL</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.coinpair.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='orderType' width={'10%'} thStyle={headerStyle}>TYPE</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                             <TableHeaderColumn dataField='dateTime' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                        </BootstrapTable>
                                                        }
                                                    </TabPanel>
                                                </Tabs>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
