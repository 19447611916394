import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL } from "../../../config/api";
import axios from "axios";
import swal from 'sweetalert';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            SEOtitle: '',
            URL: '',
            SEOdescription: '',
            status: '',
            story: ''
        }
    }

    componentDidMount() {
        let editData = localStorage.getItem('blogData', '')
        editData = JSON.parse(editData)
        console.log("On edit admin: ", editData);
        this.setState({
            title: editData.title,
            SEOtitle: editData.seoTitle,
            URL: editData.url,
            SEOdescription: editData.seoDescription,
            status: editData.status,
            story: editData.story
        })
    }

    saveNews = async () => {
        let newsData = {
            "title": this.state.title,
            "seoTitle": this.state.SEOtitle,
            "url": this.state.URL,
            "seoDescription": this.state.SEOdescription,
            "story": this.state.story,
            "status": this.state.status,
            "type": 'news'
        }

        axios.post(API_URL.ANNOUNCEMENT_ADD, newsData)
            .then(res => {
                console.log("response:", res);
                if (res.data.message === "addedSuccessfully") {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    resetStates = () => {
        this.setState({
            title: '',
            SEOtitle: '',
            URL: '',
            SEOdescription: '',
            status: '',
            story: ''
        })
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Edit News</h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <Button color="primary" style={{float: 'right'}}
                                            onClick={() => {
                                                this.props.history.push('/news')
                                            }}>Back</Button>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody style={{ marginBottom: '20px' }}>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Title</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.title}
                                                            placeholder="Enter title"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    title: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>URL</Label>
                                                        <Input
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.URL}
                                                            placeholder="Enter URL"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    URL: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>SEO Title</Label>
                                                        <Input
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.SEOtitle}
                                                            placeholder="Enter SEO title"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    SEOtitle: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>SEO Description</Label>
                                                        <Input
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.SEOdescription}
                                                            placeholder="Enter SEO description"
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    SEOdescription: e.target.value
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-12">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Story</Label>
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            placeholder="Hello"
                                                            data = {this.state.story}
                                                            // content={this.state.story}
                                                            value={this.state.story}
                                                            onInit={editor => {
                                                                editor.data.set(this.state.story)
                                                                console.log('Editor is ready to use!', editor);
                                                            }}

                                                            config={{
                                                                ckfinder: { uploadUrl: 'http://localhost:5000/image/uploads' }
                                                            }}

                                                            onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                console.log("Data: ", data);
                                                                this.setState({
                                                                    story: data
                                                                })
                                                                // console.log("Change", {event, editor, data });
                                                            }}
                                                            onBlur={(event, editor) => {
                                                                console.log('Blur.', editor);
                                                            }}
                                                            onFocus={(event, editor) => {
                                                                console.log('Focus.', editor);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Status</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            style={input}
                                                            value={this.state.status}
                                                            onChange={e => {
                                                                console.log(e.target.value)
                                                                this.setState({
                                                                    status: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="Active" >Active</option>
                                                            <option value="Inactive">Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="col-md-6">
                                                        <FormGroup>
                                                            <Label for="exampleFile" style={label}>File</Label>
                                                            <Input type="file" name="file" id="exampleFile" />
                                                        </FormGroup>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <Button
                                                        onClick={this.saveNews}
                                                        style={{
                                                            marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                            marginLeft: '15px'
                                                        }}>Save</Button>
                                                    <Button color="secondary"
                                                        onClick={this.resetStates}>Reset</Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
