import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row, Label } from 'reactstrap';
import { API_URL, BASE_URL } from "../../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

let data = [];
var page = 1;

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class WithdrawList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: []
        }
    }

   async UNSAFE_componentWillMount() {
        await axios.get(API_URL.TOKEN_COUNT)
            .then(res => {
                this.setState({ count: res.data.data })
                console.log("Count:", res.data)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        this.getTokenData();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
    }
    getTokenData = async (page) => {

        await axios.post(API_URL.ERC20_TOKEN_LIST_ALL, {
            "pageNumber": page,
            "searchBy": ""
        })
            .then(res => {
                let tokenData = res.data.data
                console.log("ERC20: ", tokenData);
                tokenData.map((d) => {
                    if (d.BTC == "1" && d.ETH == "1" && d.USDT == "1") {
                        d["pair"] = "BTC-ETH-USDT"
                    } else if (d.BTC == "0" && d.ETH == "1" && d.USDT == "1") {
                        d["pair"] = "ETH-USDT"
                    } else if (d.BTC == "1" && d.ETH == "0" && d.USDT == "1") {
                        d["pair"] = "BTC-USDT"
                    } else if (d.BTC == "1" && d.ETH == "1" && d.USDT == "0") {
                        d["pair"] = "BTC-ETH"
                    } else if (d.BTC == "0" && d.ETH == "0" && d.USDT == "1") {
                        d["pair"] = "USDT"
                    } else if (d.BTC == "1" && d.ETH == "0" && d.USDT == "0") {
                        d["pair"] = "BTC"
                    } else (
                        d["pair"] = "ETH"
                    )
                })
                this.setState({
                    tokenData: tokenData
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    handleSearch = async (e) => {
        e.preventDefault();
        let dataObj = {
            "searchBy": this.state.searchBy,
            "searchField": this.state.searchField,
            "pageNumber": this.state.page
        }
        await axios.post(API_URL.ERC20_TOKEN_LIST_ALL, dataObj)
            .then(res => {
                let tokenData = res.data.data
                console.log("ERC20: ", tokenData);
                tokenData.map((d) => {
                    if (d.BTC == "1" && d.ETH == "1" && d.USDT == "1") {
                        d["pair"] = "BTC-ETH-USDT"
                    } else if (d.BTC == "0" && d.ETH == "1" && d.USDT == "1") {
                        d["pair"] = "ETH-USDT"
                    } else if (d.BTC == "1" && d.ETH == "0" && d.USDT == "1") {
                        d["pair"] = "BTC-USDT"
                    } else if (d.BTC == "1" && d.ETH == "1" && d.USDT == "0") {
                        d["pair"] = "BTC-ETH"
                    } else if (d.BTC == "0" && d.ETH == "0" && d.USDT == "1") {
                        d["pair"] = "USDT"
                    } else if (d.BTC == "1" && d.ETH == "0" && d.USDT == "0") {
                        d["pair"] = "BTC"
                    } else (
                        d["pair"] = "ETH"
                    )
                })
                this.setState({
                    tokenData: tokenData
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    async handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        await axios.post(API_URL.USER_INFORMATION, {
            "pageNumber": page
        })
        this.getTokenData(page);

    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if (row.active === true) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Active
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.active === false) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Inactive
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.active === "true") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Active
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.active === "false") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Inactive
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    deleteUser = async (row) => {
        console.log("Indelete: ", row);
        let tokenData = {
            "id": row._id,
            "active": "0"
        }
        console.log("Add data: ", tokenData);
        await axios.post(API_URL.DELETE_TOKEN, tokenData)
            .then(res => {
                console.log(res);
                if (res.data.message == "deleted") {
                    swal("Deleted Successfully", "", "success")
                    this.getTokenData();
                } else {
                    swal("Error!", "", "error")
                }
            })
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    {/* <div class="col-md-6">
                        <a href="#" className="btn btn-block btn-danger btn-xs"
                            style={{ width: '140%' }}
                            onClick={() => {
                                console.log("Cell value: ", row)
                                this.deleteUser(row)
                            }}>
                            <i className="fa fa-times" />
                        </a>
                    </div> */}
                    <div class="col-md-6">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            style={{ width: '140%' }}
                            onClick={() => {
                                let data = JSON.stringify(row)
                                console.log(row);
                                localStorage.setItem('tokenEditData', data)
                                this.props.history.push("/tokenedit")
                            }}>
                            <i className="fa fa-edit " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    tokenImage(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    <div class="col-md-6">
                        <img src={`${BASE_URL}coinicon/${row.tokenSymbol}.png`} width="20px" height="20px" />
                    </div>
                    <div class="col-md-6">
                        <p>{row.tokenName}</p>
                    </div>
                </div>
            </div>
        )
    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Coins List</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Coins List
                                                </CardHeader>
                                                <CardBody>
                                                    <form onSubmit={this.handleSearch}>
                                                        <div className="row" >
                                                            <div className="col-md-6">
                                                                <div className="row">
                                                                    <div className="col-md-2.5">
                                                                        <Label>Search By: </Label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                        <select
                                                                            className="form-control editor edit-text"
                                                                            value={this.state.searchBy}
                                                                            style={{ width: '100%', height: '100%', fontSize: '13px' }}
                                                                            onChange={(e) => {
                                                                                this.setState({
                                                                                    searchBy: e.target.value
                                                                                })
                                                                            }}>
                                                                            <option value="">-- select --</option>
                                                                            <option value="tokenName">Token Name</option>
                                                                            <option value="tokenSymbol">Token Symbol</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input value={this.state.searchField} onChange={(e) => {
                                                                    this.setState({ searchField: e.target.value })
                                                                }} placeholder="search"
                                                                    style={{ width: '60%', fontSize: '13px', marginRight: '20px', marginBottom: '10px', height: '70%', marginLeft: '-80px' }} />
                                                                <button className="btn btn-primary" >Submit</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                    <BootstrapTable data={this.state.tokenData} 
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'5%'}>No.</TableHeaderColumn>
                                                        <TableHeaderColumn isKey
                                                            dataField='tokenName'
                                                            dataFormat={this.tokenImage.bind(this)}
                                                            width={'15%'}
                                                            dataSort={true}
                                                            thStyle={headerStyle}>TOKEN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='tokenSymbol' width={'8%'} thStyle={headerStyle}>SYMBOL</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='pair' width={'10%'} thStyle={headerStyle} tdStyle={{ whiteSpace: 'normal' }}>PAIR</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='contractAddress' width={'27%'} thStyle={headerStyle}>CONTRACT ADDRESS</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'8%'}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'13%'} thStyle={headerStyle} tdStyle={{ whiteSpace: 'normal' }}>DATE</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.cellEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'10%'}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>
                                                    <div style={{ marginTop: '20px', float: 'right' }}>
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={10}
                                                            totalItemsCount={this.state.count}
                                                            pageRangeDisplayed={5}
                                                            onChange={this.handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
