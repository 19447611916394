import React, { Component, useEffect, useState } from 'react';
import Header from '../../Component/Header'
// import Footer from '../Component/Footer';
import Menu from '../../Component/Menu';
import { API_URL } from "../../config/api";
import axios from "axios";
import swal from 'sweetalert';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";


const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export const AddStake= ()=> {
   
    // const [bankDetais, setBankDetais] = useState()
const [packageName, setPackageName] = useState('');
const [lowerpriceLimit, setLowerpriceLimit] = useState('');
const [upperpriceLimit, setUpperpriceLimit] = useState('');
const [interestPercentage, setInterestPercentage] = useState('');
const [stakingPeriod, setStakingPeriod] = useState('');

useEffect(() => {
    
}, []);
// const getBanks = async()=>{
//     await axios.post(API_URL.GET_BANK).then(res=>{
//         if(res.data.statusCode === 200 && res.data.data && res.data.data.length){
//             setAccHolderName(res.data.data[0].accHolderName)
//             setBankName(res.data.data[0].bankName)
//             setAccountNo(res.data.data[0].accountNo)
//             setBranch(res.data.data[0].branch)
//             setIfsCode(res.data.data[0].IFSCode)
//             setID(res.data.data[0]._id)
//         }
//     }).catch(err=>{

//     })
// }
    const addStaking = async () => {
        const a = localStorage.getItem('loginData')
        const adminDetails = a ? JSON.parse(a) : null;
        if (!adminDetails) {
            this.props.history.push("/login")
        }
        let stake = {
            packageName: packageName,
            lowerpriceLimit:+lowerpriceLimit,
            upperpriceLimit:+upperpriceLimit,
            interestPercentage:+interestPercentage,
            stakingPeriod:+stakingPeriod,
            createdBy:adminDetails?._id,
            userId:adminDetails?._id
        }

        await axios.post(API_URL.ADD_STACKE, stake)
            .then(res => {
                console.log("Balance:", res.data);
                if (res.data.statusCode === 200) {
                    swal(res.data.message, "", "success");
                    cancleChange()
                } else {
                    swal("Error", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

   const cancleChange = () => {
                setPackageName('')
                setLowerpriceLimit('')
                setUpperpriceLimit('')
                setInterestPercentage('')
                setStakingPeriod('')
    }

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Staking Package Master</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="achn" style={label}>Package Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="achn"
                                                            id="achn"
                                                            style={input}
                                                            value={packageName}
                                                            placeholder="Package Name"
                                                            onChange={e => {
                                                                setPackageName(e.target.value)
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="lowerpriceLimit" style={label}>Lower Price Limit</Label>
                                                        <Input
                                                            type="number"
                                                            name="lowerpriceLimit"
                                                            id="lowerpriceLimit"
                                                            style={input}
                                                            value={lowerpriceLimit}
                                                            placeholder="Lower Price Limit"
                                                            onChange={e => {
                                                                setLowerpriceLimit(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="upperpriceLimit" style={label}>Upper Price Limit</Label>
                                                        <Input
                                                            type="number"
                                                            name="upperpriceLimit"
                                                            id="upperpriceLimit"
                                                            value={upperpriceLimit}
                                                            style={input}
                                                            placeholder="Upper Price Limit"
                                                            onChange={e => {
                                                                setUpperpriceLimit(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="interestPercentage" style={label}>Interest Percentage</Label>
                                                        <Input
                                                            type="number"
                                                            name="interestPercentage"
                                                            id="interestPercentage"
                                                            style={input}
                                                            value={interestPercentage}
                                                            placeholder="Interest Percentage"
                                                            onChange={e => {
                                                                setInterestPercentage(e.target.value)
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-4">
                                                    <FormGroup>
                                                        <Label for="stakingPeriod" style={label}>Staking Period</Label>
                                                        <Input
                                                            type="number"
                                                            name="stakingPeriod"
                                                            id="stakingPeriod"
                                                            style={input}
                                                            value={stakingPeriod}
                                                            placeholder="Staking Period"
                                                            onChange={e => {
                                                                setStakingPeriod(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-4"></div>
                                          

                                                <Button
                                                    onClick={addStaking}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '10px'
                                                    }}>Update</Button>
                                                <Button color="secondary"
                                                    onClick={cancleChange}>Cancel</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    
}
