import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL } from "../../../config/api";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

var id;
var editData = [];
var status= '';

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userBalace: [],
            tokenValue: [],
            depositeHistory: [],
            withdrawalHistory: [],
            activationStatus: ''

        }
    }

    componentDidMount() {
        let emailData = localStorage.getItem('emailData', '')
        console.log("Email: ", emailData);
        id = emailData
        editData = localStorage.getItem('kycEdit', '')
        editData = JSON.parse(editData)
        console.log("On edit user: ", editData);
        id = editData._id
        this.setState({
            tokenData: editData,
            email: editData.email,
            phoneNumber: editData.phoneNumber,
            kyc: editData.kyc,
            fullname: editData.fullname,
            country: editData.country,
            dateofbirth: editData.dateofbirth,
            acno: editData.acno,
            ifsc: editData.ifsc,
            address: editData.address,
            zipcode: editData.zipcode,
            doctype: editData.doctype,
            docidno: editData.docidno,
            frontImage: editData.frontImage.includes('var/www/html') ? 'https://bitallx.com/kycimage'+editData.frontImage.split('html')[1] : editData.frontImage,
            backImage: editData.backImage.includes('var/www/html') ? 'https://bitallx.com/kycimage'+editData.backImage.split('html')[1] : editData.backImage,
            bankname: editData.bankname,
            ifsc: editData.ifsc,
            userId: editData.userId,
            id: editData._id,
            rejectionReason: editData.rejectionReason,
            passbook: editData.passbook.includes('var/www/html') ? 'https://bitallx.com/kycimage'+editData.passbook.split('html')[1] : editData.passbook,
            selfieImage: editData.selfie.includes('var/www/html') ? 'https://bitallx.com/kycimage'+editData.selfie.split('html')[1] : editData.selfie,
        })
    }


    updateStatus = async () => {
        if (this.state.activationStatus === "Inactive") {
            editData["activationStatus"] = 0
        } else {
            editData["activationStatus"] = 1
        }

        if(this.state.kyc === 0) {
            status = "Pending"
        } else if(this.state.kyc === 1){
            status = "Approved"
        } else if(this.state.kyc === 2){
            status = "Not Initiated"
        } else { 
            status = "Rejected"
        }
        let data = {
            "id": this.state.userId,
            "emailId":this.state.email,
            "kyc": this.state.kyc,
            "status": status,
            "kycId": this.state.id,
            "rejectionReason": this.state.rejectionReason
        }
        console.log(data);

        await axios.post(API_URL.KYC_UPDATE, data)
            .then(res => {
                console.log("User update:", res.data);

                if (res.data.message === "updatedSuccessuly") {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
                // this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    render() {
        // console.log(this.state.tokenValue);

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">KYC Verification</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    localStorage.setItem('userData', '')
                                                    this.props.history.push('/kyc')
                                                }}
                                                Style="float:right; margin: 20"> Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.email}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Full Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.fullname}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Date of Birth</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.dateofbirth}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Phone Number</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.phoneNumber}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Address</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.address}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 

                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Zipcode</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.zipcode}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Country</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.country}
                                                            disabled />
                                                    </FormGroup>
                                                </div>

                                                <div class="col-md-6"> 
                                                </div>

                                                <div class="col-md-12"> 
                                                <hr></hr>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Document Type</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.doctype}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Document ID No.</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.docidno}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-4"> 
                                                        <FormGroup>
                                                            <Label for="examplePassword" style={{
                                                                fontSize: '14px',
                                                                color: '#646089',
                                                                fontWeight: '600',
                                                                marginRight: '20px'
                                                            }}>Front Image</Label>
                                                            <a href={this.state.frontImage} target="_blank">
                                                            <img src={this.state.frontImage} alt="boohoo" className="img-responsive" style={{border:"1px solid #000"}} width="100" height="100"/>
                                                            </a>
                                                        </FormGroup>
                                                </div>
                                                <div class="col-md-4"> 
                                                        <FormGroup>
                                                            <Label for="examplePassword" style={{
                                                                fontSize: '14px',
                                                                color: '#646089',
                                                                fontWeight: '600',
                                                                marginRight: '20px'
                                                            }}>Back Image</Label>
                                                            <a href={this.state.backImage}  target="_blank">
                                                            <img src={this.state.backImage} alt="boohoo" className="img-responsive" style={{border:"1px solid #000"}} width="100" height="100"/>
                                                            </a>
                                                        </FormGroup>
                                                </div>
                                                <div class="col-md-4"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={{
                                                            fontSize: '14px',
                                                            color: '#646089',
                                                            fontWeight: '600',
                                                            marginRight: '20px'
                                                        }}>Selfie Image</Label>
                                                        <a href={this.state.selfieImage}  target="_blank">
                                                        <img src={this.state.selfieImage} alt="boohoo" className="img-responsive" style={{border:"1px solid #000"}} width="100" height="100"/>
                                                        </a>
                                                    </FormGroup>
                                                </div>
                                               
                                                <div class="col-md-12"> 
                                                <hr></hr>
                                                </div>

                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Bank Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.bankname}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>AC No.</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.acno}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>IFSC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.ifsc}
                                                            disabled />
                                                    </FormGroup>
                                                </div>
                                                <div class="col-md-6"> 
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={{
                                                            fontSize: '14px',
                                                            color: '#646089',
                                                            fontWeight: '600',
                                                            marginRight: '20px'
                                                        }}>Passbook Image</Label>
                                                        <a href={this.state.passbook}  target="_blank">
                                                        <img src={this.state.passbook} alt="boohoo" className="img-responsive" style={{border:"1px solid #000"}} width="100" height="100"/>
                                                        </a>
                                                    </FormGroup>
                                                </div>

                                                
                                                
                                                <div class="col-md-12"> 
                                                <hr></hr>
                                                </div>
                                                <div class="col-md-6"> 
                                                </div>
                                                <div class="col-md-6"> 
                                                </div>
 
                                                <div class="col-md-6">
                                                   
                                                    <div className="row">
                                                       
                                                        <div className="col-md-6">
                                                        
                                                        </div>
                                                    </div>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>KYC Verification</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.kyc}
                                                            style={input}
                                                            onChange={e => {
                                                                let num = Number(e.target.value)
                                                                this.setState({
                                                                    kyc: num,
                                                                })
                                                            }}>
                                                            <option value="1">Approved</option>
                                                            <option value="0">Pending</option>
                                                            <option value="2">Not Initiated</option>
                                                            <option value="3">Rejected</option>
                                                        </select>
                                                    </FormGroup>

                                                  

                                                    <Button
                                                        onClick={this.updateStatus}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleUpdate}>Cancel</Button>

                                                </div>
                                                <div class="col-md-6">

                                                    
                                                    <div className ="row">
                                                        <div className="col-md-6">
                                                        </div>
                                                        <div className="col-md-6">
                                                        
                                                        </div>
                                                    </div>
                                                   
                                                    <FormGroup>
                                                       <Label for="examplePassword" style={label}>KYC REJECTION REASON</Label>
                                                       <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            style={input}
                                                            value={this.state.rejectionReason}
                                                            onChange={e => {
                                                                this.setState({
                                                                    rejectionReason: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                   </FormGroup>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
