import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import bcrypt from 'bcryptjs';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL } from "../../../config/api";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './userTable.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';

var id;
var editData = [];
var ercCoinData = [];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userBalace: [],
            kycData: [],
            tokenValue: [],
            depositeHistory: [],
            withdrawalHistory: [],
            activationStatus: '',
            btnName: 'Lock',
            coinName: '',
            amountToUse: 0,
            lockedDate:'',
            tokenNameForAddBalance:'',
            amountToUpdate:'',
            coinUsdPrice:1,
            lockedBalHistory:[]
        }
    }

    async componentDidMount() {
        let userData = localStorage.getItem('userData');
        userData = JSON.parse(userData)
        console.log("User Data: ", userData)
        let emailData = localStorage.getItem('emailData', '')
        console.log("Email: ", emailData);
        id = userData._id
        await axios.post(API_URL.USER_BY_ID, {
            "id": id
        })
            .then(res => {
                console.log("user by id:", res.data.data);
                this.setState({
                    tokenData: res.data.data,
                    activationStatus: res.data.data.activationStatus
                })
                var password = bcrypt.decodeBase64("$2a$08$JNy8IrnisCtgphxxV1TVB.SbyIi7ASuSgGhL56fEBm4Kp073N6GT2");

                console.log(password);

            })
            .catch(error => {
                console.log("Error: ", error);
            })

            await axios.post(BASE_URL+"kyc/getid", {
                "id": id
            })
                .then(res => {
                    console.log("kycdata", res.data.data);
                    this.setState({
                        kycData: res.data.data
                    })
                })
                .catch(error => {
                    console.log("kycdata", error);
                })
            
        // this.tableData()
        this.getUserBalance()
        this.getDepositeHistory()
        this.getWithdrawalHistory();
        this.getLockedBalHistory();
    }

    updateUserBalance = async () => {
        var obj = {}
        // obj['userId'] = id;
        await Promise.all(this.state.tokenValue.map((d) => {
            obj[d.tokenSymbol] = Number(Number(this.state[d.tokenSymbol + "_balance"]).toFixed(8))
        }))
        await axios.post(BASE_URL + "userbalance/updatebalance", {
            "userId": id,
            "data": obj
        })
            .then(res => {
                if (res.data.message === "success") {
                    swal("Updated Successfully", "", "success");
                    this.getUserBalance();
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    lockUserBalance = async () => {
        var lockBalance;
        var remainbalance;
        var obj = {}
        console.log("sachin here");
        console.log(this.state.lockedDate,this.state.coinName);
if (this.state[this.state.coinName +'_lockedAmount'] && this.state[this.state.coinName +'_lockDate']) {
    
        if (this.state.coinBalance[this.state.coinName] >= this.state.amountToUse) {  
            console.log("sachin here");
            //wether lock amount is less than or equal to balance
            if (this.state.coinBalance.hasOwnProperty([this.state.coinName + "_lock"])) {    //wether the FESS_lock field already exists
                if (this.state.coinBalance[this.state.coinName + "_lock"] >= 0) {             // FESS_lock balance is greater than zero 
                    remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                    lockBalance = this.state.coinBalance[this.state.coinName + "_lock"] + Number(Number(this.state.amountToUse).toFixed(6))
                    // console.log("Remain Balance: ", remainbalance)
                    // console.log("Lock Balance: ", lockBalance)
                } else {
                    remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                    lockBalance = this.state.amountToUse
                    // console.log("Remain Balance1: ", remainbalance)
                    // console.log("Lock Balance1: ", lockBalance)
                }
                let balanceUpdate = {
                    "id": id,
                    "amountLocked": Number(Number(lockBalance).toFixed(6)),
                    "remaining": Number(Number(remainbalance).toFixed(6)),
                    "type": "locked",
                    "coinName": this.state.coinName,
                    "lockedDate":this.state.lockedDate
                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/lock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                            this.getLockedBalHistory()
                            this.setState({[this.state.coinName +'_lockedAmount']:''})
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })
            } else {
                remainbalance = this.state.coinBalance[this.state.coinName] - this.state.amountToUse
                lockBalance = this.state.amountToUse

                let balanceUpdate = {
                    "id": id,
                    "amountLocked": Number(Number(lockBalance).toFixed(6)),
                    "remaining": Number(Number(remainbalance).toFixed(6)),
                    "type": "locked",
                    "coinName": this.state.coinName,
                    "lockedDate": this.state[this.state.coinName + "_lockDate"]
                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/lock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                            this.getLockedBalHistory()
                           this.setState({[this.state.coinName +'_lockedAmount']:'',[this.state.coinName +'_lockDateValue']:''})
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })
            }
        } else {
            swal("Not enough balance!", "", "error");
        }
    }else{
        swal("Enter amount and Date !", "", "error");
    }
    }

    unlockUserBalance = async(rowId) =>{
        var unlockBalance;
        var remainbalance;

        if(this.state.coinBalance.hasOwnProperty([this.state.coinName + "_lock"])){
            if(this.state.coinBalance[this.state.coinName + "_lock"] >= 0 && this.state.coinBalance[this.state.coinName + "_lock"] >= this.state.amountToUse){
                remainbalance = Number(Number(this.state.coinBalance[this.state.coinName]).toFixed(6)) + Number(Number(this.state.amountToUse).toFixed(6))
                unlockBalance = this.state.coinBalance[this.state.coinName + "_lock"] - this.state.amountToUse

                console.log("Remain Balance1: ", remainbalance)
                console.log("Unlock Balance1: ", unlockBalance)

                let balanceUpdate = {
                    "id": id,
                    "amountLocked": '',
                    "remaining": Number(Number(remainbalance + Number(unlockBalance)).toFixed(6)),
                    "type": "unlocked",
                    "coinName": this.state.coinName,
                    "lockedDate":this.state.lockedDate,
                    "rowId":rowId
                }
                console.log("Balance Data: ", balanceUpdate)
                await axios.post(BASE_URL + "balance/unlock", balanceUpdate)
                    .then(res => {
                        if (res.data.message === "updatedSuccessully") {
                            swal("Updated Successfully", "", "success");
                            this.getUserBalance();
                            this.getLockedBalHistory()
                        } else {
                            swal("Unable to add", "", "error");
                        }
                    })
                    .catch(error => {
                        console.log("Error: ", error);
                    })

            } else{
                swal("Not sufficient locked balance!", "", "error");
            }
        }   else {
            swal("No locked balance!", "", "error");
        }
    }


    getUserBalance = async () => {
        await axios.post(API_URL.USER_BALANCE, {
            "userId": id
        })
            .then(res => {
                // console.log("Edit user Balance:", res.data.data);
                ercCoinData = res.data.data

                this.setState({ coinBalance: res.data.data })
                axios.get(API_URL.ERC20_TOKEN_LIST)
                    .then(res => {
                        // console.log("usertoken:", res.data.data);
                        this.setState({ tokenValue: res.data.data })
                        this.state.tokenValue.map((d) => {
                            if (this.state.coinBalance[d.tokenSymbol + "_lock"]) {
                                // if(this.state.coinBalance.hasOwnProperty(this.state.coinBalance[d.tokenSymbol]+"_lock")){
                                this.setState({ [d.tokenSymbol + "_lock"]: Number(this.state.coinBalance[d.tokenSymbol + "_lock"]) })
                            } 
                            else {
                                let val = d.tokenSymbol + "_lock"
                                this.setState({
                                    [d.tokenSymbol + "_lock"]: 0
                                })
                            }

                            if(this.state.coinBalance[d.tokenSymbol + "_lockDate"]){
                                console.log(this.state.coinBalance[d.tokenSymbol + "_lockDate"]);
                                this.setState({ [d.tokenSymbol + "_lockDate"]: this.state.coinBalance[d.tokenSymbol + "_lockDate"] })
                                console.log(this.state[d.tokenSymbol + "_lockDate"]);

                            }
                            this.setState({
                                [d.tokenSymbol + "_balance"]: Number(this.state.coinBalance[d.tokenSymbol]),
                                // [d.tokenSymbol + "_lock"]: Number(this.state.coinBalance[d.tokenSymbol+"_lock"])
                            })
                        })
                    })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }
    addBalance = async()=>{

        await axios.post(API_URL.ADD_BALANCE, {
            "userId": id,
            "coinName": this.state.tokenNameForAddBalance,
            "amount": +this.state.amountToUpdate,
            "usdPrice": +this.state.coinUsdPrice
        })
            .then(res => {
                if (res.data.successCode = 200) {
                    this.getUserBalance()
                    swal("Updated Successfully", "", "success");
                    this.setState({amountToUpdate:''})
                }
            }).catch(e=>{
                swal("Something went wront", "", "error");
            })
    }
    onTokenChange = async(value)=>{
        this.setState({tokenNameForAddBalance:value})
        let CoinName = value + 'USDT'
        if (value.toLowerCase() != 'usdt') {
            await axios.get(`https://api.wazirx.com/sapi/v1/ticker/24hr?symbol=` + CoinName.toLowerCase()).then((res) => {
                if (res && res.data) {
                    this.setState({ coinUsdPrice: res.data.lastPrice })
                }
            })
        } else {
            this.setState({ coinUsdPrice: 1 })
        }
    }
    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }

    indexW(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    getDepositeHistory = async () => {
        await axios.post(API_URL.DEPOSITE_HISTORY, {
            "userId": id
        })
            .then(res => {
                // console.log("Deposite History:", res.data.data);
                this.setState({ depositeHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getWithdrawalHistory = async () => {
        await axios.post(API_URL.WITHDRAW_HISTORY, {
            "userId": id
        })
            .then(res => {
                // console.log("Withdrawal History:", res.data.data);
                this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    updateStatus = async () => {
        if (this.state.activationStatus === "Inactive") {
            editData["activationStatus"] = 0
        } else {
            editData["activationStatus"] = 1
        }
        let data = {
            "id": id,
            "activationStatus": Number(this.state.activationStatus)
        }
        console.log(data);

        await axios.post(API_URL.UPDATE_USER, data)
            .then(res => {
                // console.log("User update:", res.data);

                if (res.data.message === "updatedSuccessuly") {
                    swal("Updated Successfully", "", "success");
                } else {
                    swal("Unable to add", "", "error");
                }
                // this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-primary btn-xs"
                            >
                                Pending
                        </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }
    unlockButtonFormatter(cell, row, enumObject, rowIndex) {
        return (
            // <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
            //     <div class="row">
            //         <div class="col-md-12">
            //             <p className="btn btn-block btn-primary btn-xs"
            //             >
                            
            //         </p>
            //         </div>
            //     </div>
            // </div>
            // <Button onClick={()=> alert()}>
            //     Unlock
            // </Button>
            <>
             {row.type === 'locked'? <Button color="primary" onClick={()=>{
                this.setState({coinName: row.coinName},()=>{this.unlockUserBalance(row._id)})
                
              }}>Unlock</Button> 
              : <Button color="secondary" >Unlock</Button> }
            </>
        )
    }
    lockstatusFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.type == "locked") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-warning btn-xs"
                            >
                                Locked
                        </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs">
                                Unlocked
                            </p>
                        </div>
                    </div>
                </div>
            )
        } 
    }
dateFormatter = (cell, row, enumObject, rowIndex)=>{
 return(
    <div>
       { new Date(row.timestamp).toDateString()} {new Date(row.timestamp).toLocaleTimeString()}
    </div>
 )
}
  getLockedBalHistory = async () =>{
    let url = 'get/lockedBalHistory';
   const res = await axios.post(BASE_URL+url, {
        "userId": id
    })
    if (res.data && res.data.data) {
       this.setState({lockedBalHistory: res.data.data})
    }
  }

    render() {

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Edit User</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                        <Button style={{marginRight:'10px'}}>
                                                <Link to={'referals/'+id} >Goto Referals</Link>
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    localStorage.setItem('userData', '')
                                                    this.props.history.push('/users')
                                                }}
                                                Style="float:right; margin: 20"> Back
                                            </Button>
                                           
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="userName" style={label}>User Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="userName"
                                                            style={input}
                                                            value={this.state.tokenData.userName}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="email" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="email"
                                                            style={input}
                                                            value={this.state.tokenData.emailId}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                   
                                                    <FormGroup>
                                                        <Label for="refCode" style={label}>Ref. Code</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="refCode"
                                                            style={input}
                                                            value={this.state.tokenData.refCode}
                                                            disabled />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="status" style={label}>STATUS</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.activationStatus}
                                                            style={input}
                                                            onChange={e => {
                                                                this.setState({
                                                                    activationStatus: e.target.value,
                                                                })
                                                            }}>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="date" style={label}>Phone Number</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="date"
                                                            style={input}
                                                            value={this.state.tokenData.phoneNumber}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                   <Button
                                                        onClick={this.updateStatus}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button>
                                                    <Button color="secondary"
                                                        onClick={this.cancleUpdate}>Cancel</Button>

                                                </div>
                                                <div class="col-md-6">
                                                <FormGroup>
                                                        <Label for="country" style={label}>Country</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="country"
                                                            style={input}
                                                            value={this.state.tokenData.country}
                                                            disabled />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="country" style={label}>Password</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="password"
                                                            style={input}
                                                            value={this.state.tokenData.password}
                                                            disabled />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="refBy" style={label}>Ref. By</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="refBy"
                                                            style={input}
                                                            value={this.state.tokenData.refBy}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="date" style={label}>Date</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="date"
                                                            style={input}
                                                            value={this.state.tokenData.createdOn}
                                                            disabled
                                                        />
                                                    </FormGroup>
                                                    <FormGroup style={label}>
                                                        Disable 2FA
                                                         {this.state.tokenData.two_factor === 0 ?
                                                            <Checkbox
                                                                checked={true}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        two_factor: 1
                                                                    }, async function () {
                                                                        let data = { "two_factor": 1, "id": this.state.id }
                                                                        await axios.post(API_URL.UPDATE_USER, data)
                                                                            .then(res => {
                                                                                console.log("User update:", res.data);

                                                                                if (res.data.message === "updatedSuccessuly") {
                                                                                    swal("Updated Successfully", "", "success");
                                                                                } else {
                                                                                    swal("Unable to add", "", "error");
                                                                                }
                                                                                // this.setState({ withdrawalHistory: res.data.data })
                                                                            })
                                                                            .catch(error => {
                                                                                console.log("Error: ", error);
                                                                            })
                                                                    })
                                                                }}
                                                                name="user"
                                                                color="primary"
                                                            /> :
                                                            <Checkbox
                                                                checked={false}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        two_factor: 0
                                                                    }, async function () {
                                                                        let data = { "two_factor": 0, "id": this.state.id }
                                                                        await axios.post(API_URL.UPDATE_USER, data)
                                                                            .then(res => {
                                                                                console.log("User update:", res.data);

                                                                                if (res.data.message === "updatedSuccessuly") {
                                                                                    swal("Updated Successfully", "", "success");
                                                                                } else {
                                                                                    swal("Unable to add", "", "error");
                                                                                }
                                                                                // this.setState({ withdrawalHistory: res.data.data })
                                                                            })
                                                                            .catch(error => {
                                                                                console.log("Error: ", error);
                                                                            })
                                                                    })
                                                                }}
                                                                name="user"
                                                                color="primary"
                                                            />
                                                        }

                                                    </FormGroup>

                                                   

                                                    

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    KYC & Bank Details
                                                </CardHeader>
                                                <div className="table-responsive" style={{ padding: '20px' }}>
                                                    <table className="table table-hover" style={{
                                                        border: '1px solid #ecf0f2',
                                                        borderCollapse: 'collapse',

                                                    }}>
                                                        <thead style={{
                                                            border: '1px solid #ecf0f2',
                                                            borderCollapse: 'collapse',
                                                            backgroundColor: '#ECF0F2'
                                                        }}>
                                                            <tr>
                                                                <th>Full Name</th>
                                                                <th>Date of Birth</th>
                                                                <th>Address</th>
                                                                <th>KYC Doc</th>
                                                                <th>Bank Details</th>
                                                                <th>Status</th>
                                                                {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.kycData.map((d) => (
                                                                <tr>
                                                                    <td>{d.fullname}</td>
                                                                    <td>{d.dateofbirth}</td>
                                                                    <td>
                                                                        <b>Address</b>: {d.address}
                                                                        <br></br>
                                                                        <b>Zip Code</b>: {d.zipcode}
                                                                        <br></br>
                                                                        <b>Country</b>: {d.country}
                                                                    </td>
                                                                    <td>
                                                                        <b>Doc Type</b>: {d.doctype}
                                                                        <br></br>
                                                                        <b>Doc Id No</b>: {d.docidno}
                                                                        <br></br>
                                                                        <b>Front Image</b>: <a href={d.frontImage} target="_blank"><img src={d.frontImage} width="50px" height="50px" target="_blank" style={{border:"1px #000 solid"}}/></a>
                                                                        <br></br>
                                                                        <b>Back Image</b>: <a href={d.frontImage} target="_blank"><img src={d.backImage} width="50px" height="50px" target="_blank" style={{border:"1px #000 solid"}}/></a>
                                                                        <br></br>
                                                                        <b>Selfie Image</b>: <a href={d.frontImage} target="_blank"><img src={d.selfieImage} width="50px" height="50px" target="_blank" style={{border:"1px #000 solid"}}/></a>
                                                                    </td>
                                                                    <td>
                                                                        <b>Bank Name</b>: {d.bankname}
                                                                        <br></br>
                                                                        <b>A/C No</b>: {d.acno}
                                                                        <br></br>
                                                                        <b>IFSC</b>: {d.ifsc}
                                                                        <br></br>
                                                                        <b>Passbook Image</b>: <a href={d.passbook} target="_blank"><img src={d.passbook} width="50px" height="50px" target="_blank" style={{border:"1px #000 solid"}}/></a>
                                                                    </td>
                                                                    <td>{d.status}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>


                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <div>User Balance</div>
                                                        <div>
                                                            <button type="button" className="btn btn-info" data-toggle="modal" data-target="#modal-default">
                                                                Update Balance
                                                            </button>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <div className="table-responsive" style={{ padding: '20px' }}>
                                                    <table className="table table-hover" style={{
                                                        border: '1px solid #ecf0f2',
                                                        borderCollapse: 'collapse',

                                                    }}>
                                                        <thead style={{
                                                            border: '1px solid #ecf0f2',
                                                            borderCollapse: 'collapse',
                                                            backgroundColor: '#ECF0F2'
                                                        }}>
                                                            <tr>
                                                                <th>Coin</th>
                                                                <th>Name</th>
                                                                <th>Balance</th>
                                                                <th>Lock Balance</th>
                                                                <th>Lock Date </th>
                                                                <th>Lock/Unlock Balance</th>
                                                                {/* <th>Available</th>
                                                    <th>In Order</th>
                                                    <th>Total</th>
                                                    <th>Action</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {this.state.tokenValue.map((d) => (
                                                                d.active == true &&
                                                                <tr>
                                                                    <td><img src={"https://bitallx.com/coinicon/" + d.tokenSymbol + ".png"} width="20px" height="20px" /></td>
                                                                    <td><span className="name">{d.tokenName} ({d.tokenSymbol})</span></td>

                                                                    <td> <input
                                                                        value={
                                                                            this.state[d.tokenSymbol + "_balance"]
                                                                        }
                                                                        onChange={(e) => {
                                                                            // ercCoinData[d.tokenSymbol] = e.target.value
                                                                            // let symbol = d.tokenSymbol
                                                                            // this.state.coinBalance[symbol].prop = e.target.value;
                                                                            // this.setState({ coinBalance: this.state.coinBalance });
                                                                            // console.log("Token change: ", d.tokenSymbol+ e.target.value)
                                                                            this.setState({
                                                                                [d.tokenSymbol + "_balance"]: e.target.value
                                                                            })
                                                                        }}  style={{opacity:0.4}}/>
                                                                    </td>
                                                                    <td style={{position:'relative'}}> 
                                                                    <input
                                                                        type="number"
                                                                        value={
                                                                            Number(this.state[d.tokenSymbol + "_lockedAmount"])
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                coinName: d.tokenSymbol, //coin name
                                                                                amountToUse: e.target.value, //amount whch we want to use for updation
                                                                                [d.tokenSymbol + "_lock"]: e.target.value,
                                                                                [d.tokenSymbol + "_lockedAmount"]: e.target.value
                                                                            })
                                                                        }}
                                                                    />
                                                                    <button className='max__btn' onClick={()=>{
                                                                        // this.setState({[d.tokenSymbol + "_lock"]:[d.tokenSymbol + "_balance"]})
                                                                        this.setState({
                                                                            coinName: d.tokenSymbol, //coin name
                                                                            amountToUse: this.state[d.tokenSymbol + "_balance"], //amount whch we want to use for updation
                                                                            [d.tokenSymbol + "_lock"]: this.state[d.tokenSymbol + "_balance"],
                                                                            [d.tokenSymbol + "_lockedAmount"]: this.state[d.tokenSymbol + "_balance"]
                                                                        })
                                                                    }}>max</button>
                                                                    </td>
                                                                    
                                                                    <td> <input
                                                                        type="date"
                                                                        value={
                                                                            this.state[d.tokenSymbol + "_lockDateValue"]
                                                                        }
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                coinName: d.tokenSymbol, //coin name
                                                                                lockedDate: e.target.value, //amount whch we want to use for updation
                                                                                [d.tokenSymbol + "_lockDate"]: e.target.value,
                                                                               [d.tokenSymbol + "_lockDateValue"]:e.target.value,
                                                                            })
                                                                        }}
                                                                    />
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            color="primary"
                                                                            size='sm'
                                                                            style={{ marginRight: '20px' }}
                                                                            onClick={this.lockUserBalance}
                                                                        >Lock</Button>
                                                                        {/* <Button color="secondary"
                                                                            onClick={this.unlockUserBalance}
                                                                        >Unlock</Button> */}
                                                                        <Button color="success"
                                                                        size='sm'
                                                                            onClick={this.updateUserBalance}
                                                                        >Update</Button>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>

                                                    {/* <Button
                                                        onClick={this.updateUserBalance}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Update</Button> */}
                                                    {/* <Button color="secondary"
                                                        onClick={this.cancleERC}>Cancel</Button> */}

                                                </div>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User History
                                                </CardHeader>
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Deposit</Tab>
                                                        <Tab>Withdrawal</Tab>
                                                        <Tab>Lock Balance History</Tab>
                                                    </TabList>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.depositeHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='any' dataFormat={this.dateFormatter} width={'20%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.withdrawalHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                    <BootstrapTable data={this.state.lockedBalHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amountLocked' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='lockedDate' width={'15%'} thStyle={headerStyle}>DATE</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.lockstatusFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.unlockButtonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >ACTION</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                </Tabs>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div className="modal fade" id="modal-default">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Token Balance</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className='form-group'>
                    <label>Token List</label>
                        <select className='form-control' onChange={(e)=>{this.onTokenChange(e.target.value)}}>
                                                    <option defaultValue={null} >Select Token</option>
                                                    {this.state.tokenValue.map((d)=>{
                                                        return(
                                                            <>
                                                            <option  value={d.tokenSymbol}>{d.tokenSymbol} ({d.tokenName})</option>
                                                            {/* <hr /> */}
                                                            </>
                                                        )
                                                    })}
                        </select>
                </div>
                <div className='form-group'>
                    <label>Amount</label>
                        <input placeholder='Amount' value={this.state.amountToUpdate}  onChange={(e)=>{this.setState({amountToUpdate:e.target.value})}} className='form-control'/>
                        <div style={{fontSize: 11}} className='text-info'>{'Amount in USD : '+ ((+this.state.amountToUpdate) * (+this.state.coinUsdPrice))}</div>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={()=>{this.addBalance()}}>Save changes</button>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
