import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';

let data = [];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class WithdrawList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            withdrawFund: []
        }
    }

    UNSAFE_componentWillMount() {
        this.getDepositeHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
    }
    getDepositeHistory = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.get(API_URL.WITHDRAW_GET)
            .then(res => {
                let userData = res.data.data
                // userData.map((d) => {
                //     let userid = {
                //         "id" : d.userId
                //     }
                //      axios.post(API_URL.USER_BY_ID,
                //         userid,
                //         {
                //           headers: {
                //             'Content-Type': 'application/json',
                //           }
                //         })
                //         .then(res => {
                //           let emailOfUser = res.data.data.emailId
                //           d["emailId"] = emailOfUser
                //         })
                //         .catch(error => {
                //           console.log("Error: ", error);
                //         })
                // })
                let userWithdrawData = res.data.data
                this.setState({
                    withdrawFund: userWithdrawData
                })
                console.log("withdrawal data:", userWithdrawData)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-warning btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Pending
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </a>
                        </div>
                    </div>
                </div>
            )
        }
    }

    cellEdit(cell, row, enumObject, rowIndex) {
        return (
           <>
           {row.type ==='Internal Withdrawal' ? <div></div>:
             <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
             <div class="col-md-12">
                 <a href="#" className="btn btn-block btn-primary btn-xs"
                     onClick={() => {
                         let data = JSON.stringify(row)
                         console.log(row);
                         localStorage.setItem('withdrawEdit', data)
                         this.props.history.push("/editwithdraw")
                     }}>
                     <i className="fa fa-edit " />
                 </a>
             </div>
         </div>
           }
           </>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                <div class="col-md-12">
                    {/* <a href=""
                        onClick={() => {
                            let data = row.userId
                            console.log("Withdraw", row.userId);
                            localStorage.setItem('emailData', data)
                            this.props.history.push("/edituser")
                        }}> */}
                    <a>
                        <p style={{ color: '#0069D9' }}>{row.userName}<br></br>({row.emailId})</p>
                    </a>
                </div>
            </div>
        )
    }


    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress || row._id}</p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Withdraw List</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Withdraw Listtt
                                                </CardHeader>
                                                <CardBody>
                                                    {/* <table style={{border:'1px solid black', borderCollapse :'collapse'}}>
                                                        <tr>
                                                            <th style={{border:'1px solid black'}}>Table Header</th>
                                                            <th style={{border:'1px solid black'}}>Table Header</th>
                                                        </tr>
                                                        <tr>
                                                            <td style={{border:'1px solid black'}}>Table cell 1</td>
                                                            <td style={{border:'1px solid black'}}>Table cell 2</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{border:'1px solid black'}}>Table cell 3</td>
                                                            <td style={{border:'1px solid black'}}>Table cell 4</td>
                                                        </tr>
                                                    </table> */}

                                                    <BootstrapTable data={this.state.withdrawFund} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn
                                                            isKey
                                                            dataField='userId'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>

                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.details.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'23%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >WALLET ADDRESS / DETAILS</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='walletAddress' width={'25%'} thStyle={headerStyle}>WALLET ADDRESS / DETAILS</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='button' width={'12%'} thStyle={headerStyle} 
                                                         dataFormat={(cell, row)=>{
                                                            return(
                                                                <div>
                                                                    {row.coinName !=="INR" ? row.network : ""}
                                                                </div>
                                                            )
                                                         }}
                                                         >NETWORK</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'8%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'13%'}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'14%'} thStyle={headerStyle}>DATE</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.cellEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'8%'}
                                                        >ACTION</TableHeaderColumn>
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
