import React from "react";

import { Route, Switch } from 'react-router-dom';

import Widgets from "./Pages/Views/widgets";
import Calender from "./Pages/Views/calender";
import Gallery from "./Pages/Views/gallery";

import Charts from "./Pages/Views/charts/charts";
import Flot from "./Pages/Views/charts/flot";
import Inline from "./Pages/Views/charts/inline";

import GeneralForms from "./Pages/Views/Forms/general";

import Simple from "./Pages/Views/Tables/simple";
import DataTable from "./Pages/Views/Tables/dataTable";

import Buttons from "./Pages/Views/UIelement/buttons";
import General from "./Pages/Views/UIelement/general";
import Icons from "./Pages/Views/UIelement/icons";
import Modals from "./Pages/Views/UIelement/modals";
import Navbar from "./Pages/Views/UIelement/navbar";
import Ribbons from "./Pages/Views/UIelement/ribbons";
import Sliders from "./Pages/Views/UIelement/sliders";
import Timeline from "./Pages/Views/UIelement/timeline";

import Dashboard from "./Pages/dashboard";
import Login from "./Pages/login";
import Users from "./Pages/UserManagement/UserList/users";
import rbclist from "./Pages/UserManagement/UserList/rbclist";
import rbclistedit from "./Pages/UserManagement/UserList/rbclistedit";
import EditUser from "./Pages/UserManagement/UserList/editUser";

import KYClist from "./Pages/UserManagement/KYCverification/kycList";
import EditKyc from "./Pages/UserManagement/KYCverification/editKyc";

import ActiveDepositeFund from "./Pages/Lists/activeDepositeFund";
import inrActiveDepositeFund from "./Pages/Lists/inractiveDepositeFund";
import WithdrawList from "./Pages/Lists/withdrawList";
import BankTransferList from "./Pages/Lists/BankTransferList";
import inrWithdrawList from "./Pages/Lists/inrwithdrawList";
import ListUserEdit from "./Pages/Lists/listUserEdit";
import PendingWithdraw from "./Pages/Lists/pendingWithdraw";
import inrPendingWithdraw from "./Pages/Lists/inrpendingWithdraw";
import RejectedWithdraw from "./Pages/Lists/rejectedWithdraw";
import inrRejectedWithdraw from "./Pages/Lists/inrrejectedWithdraw";
import CompletedWithdraw from "./Pages/Lists/completedWithdraw";
import inrCompletedWithdraw from "./Pages/Lists/inrcompletedWithdraw";
import EditWithdraw from "./Pages/Lists/editWithdrawStatus";
import inrEditWithdraw from "./Pages/Lists/editinrWithdrawStatus";
import inrEditDeposit from "./Pages/Lists/editinrDepositStatus";
import TokenList from "./Pages/ERC20/ercTokenList";
import EditToken from "./Pages/ERC20/editToken";
import AddToken from "./Pages/ERC20/addToken";

import CreateAdmin from "./Pages/UserManagement/Admin/createAdmin";
import AdminList from "./Pages/UserManagement/Admin/adminList";
import EditAdmin from "./Pages/UserManagement/Admin/editAdmin";

import ChangePassword from "./Pages/changePassword";
import bankaccount from "./Pages/bankaccount";
import MemberBalance from "./Pages/memberBalance";
import ViewMember from "./Pages/viewMember";
import Transaction from "./Pages/transaction";

import ActivityLog from "./Pages/activityLog";

import News from "./Pages/Manage/News/news";
import AddNews from "./Pages/Manage/News/addNews";
import EditNews from "./Pages/Manage/News/editNews";

import Blogs from "./Pages/Manage/Blogs/blogs";
import AddBlogs from "./Pages/Manage/Blogs/addBlogs";
import EditBlogs from "./Pages/Manage/Blogs/editBlogs";

import feessubscription from "./Pages/Manage/Fees/feessubscription";
import feessubscriptionedit from "./Pages/Manage/Fees/feessubscriptionedit";
import feeshistory from "./Pages/Manage/Fees/feeshistory";


import FAQs from "./Pages/Manage/FAQs/faqs";
import AddFAQs from "./Pages/Manage/FAQs/addFaq";
import EditFAQs from "./Pages/Manage/FAQs/editFaq";

import SliderImage from "./Pages/Manage/Slider/sliderImages";
import AddSlider from "./Pages/Manage/Slider/addSlider";
import StakingMaster from "./Pages/Staking/StakingMaster";
import { AddStake } from "./Pages/Staking/addStake";
import UserStake from "./Pages/Staking/userStakeList";
import ReferalTable from "./Pages/Staking/referalTable";
import { UpdateStake } from "./Pages/Staking/editStakingMaster";
import EditBankTransferStatus from "./Pages/Lists/editBankTransfers";



import AddCoinPairs from './Pages/ERC20/addCoinPairs';
import FutureCoinPairs from './Pages/ERC20/futureCoinpair';
import WorkInProgress from "./Component/workInProgress/WorkInProgress";


const Routes = () => {
    return (
        <Switch>
             <Route exact path="/" component={Login} />
             {/* <Routes exact path="/" component={WorkInProgress} /> */}
             <Route exact path="/dashboard" component={Dashboard} />
             <Route exact path="/changepassword" component={ChangePassword} />
             <Route exact path="/bankaccount" component={bankaccount} /> 

             <Route exact path="/users" component={Users} />
             <Route exact path="/rbclist" component={rbclist} />
             <Route exact path="/rbclistedit" component={rbclistedit} />
             
             <Route exact path="/edituser" component={EditUser} />
             <Route exact path="/depositefund" component={ActiveDepositeFund} />
             <Route exact path="/inrdepositefund" component={inrActiveDepositeFund} />
             <Route exact path="/withdrawlist" component={WithdrawList} />

             <Route exact path="/inrwithdrawlist" component={inrWithdrawList} />
             <Route exact path="/pendingwithdraw" component={PendingWithdraw} />
             <Route exact path="/inrpendingwithdraw" component={inrPendingWithdraw} />
             <Route exact path="/completedwithdraw" component={CompletedWithdraw} />
             <Route exact path="/inrcompletedwithdraw" component={inrCompletedWithdraw} />
             <Route exact path="/rejectedwithdraw" component={RejectedWithdraw} />
             <Route exact path="/inrrejectedwithdraw" component={inrRejectedWithdraw} />
             <Route exact path="/listuseredit" component={ListUserEdit} />
             <Route exact path="/editwithdraw" component={EditWithdraw} />
             <Route exact path="/inreditwithdraw" component={inrEditWithdraw} />
             <Route exact path="/inreditdeposit" component={inrEditDeposit} /> 
             <Route exact path="/erctokenlist" component={TokenList} />
             <Route exact path="/tokenedit" component={EditToken} />
             <Route exact path="/addtoken" component={AddToken} />

             <Route exact path="/widgets" component={Widgets} />
             <Route exact path="/gallery" component={Gallery} />
             <Route exact path="/calender" component={Calender} />

             <Route exact path="/generalforms" component={GeneralForms} />

             <Route exact path="/simple" component={Simple} />
             <Route exact path="/datatable" component={DataTable} />

             <Route exact path="/charts" component={Charts} />
             <Route exact path="/inline" component={Inline} />
             <Route exact path="/flot" component={Flot} />
             <Route exact path="/buttons" component={Buttons} />
             <Route exact path="/general" component={General} />
             <Route exact path="/icons" component={Icons} />
             <Route exact path="/modals" component={Modals} />
             <Route exact path="/navbar" component={Navbar} />
             <Route exact path="/ribbons" component={Ribbons} />
             <Route exact path="/sliders" component={Sliders} />
             <Route exact path="/timeline" component={Timeline} />

             <Route exact path="/createadmin" component={CreateAdmin} />
             <Route exact path="/adminlist" component={AdminList} />
             <Route exact path="/memberbalance" component={MemberBalance} />
             <Route exact path="/viewmember" component={ViewMember} />
             <Route exact path="/transaction" component={Transaction} />
             <Route exact path="/editadmin" component={EditAdmin} />

             <Route exact path="/activitylog" component={ActivityLog} />

             <Route exact path="/news" component={News} />
             <Route exact path="/addnews" component={AddNews} />
             <Route exact path="/editnews" component={EditNews} />

             <Route exact path="/blogs" component={Blogs} />
             <Route exact path="/addblogs" component={AddBlogs} />
             <Route exact path="/editblogs" component={EditBlogs} />

             <Route exact path="/faqs" component={FAQs} />
             <Route exact path="/addfaqs" component={AddFAQs} />
             <Route exact path="/editfaqs" component={EditFAQs} />

             <Route exact path="/kyc" component={KYClist} />
             <Route exact path="/editKyc" component={EditKyc} />

             <Route exact path="/sliderlist" component={SliderImage} />
             <Route exact path="/addslider" component={AddSlider} />

             <Route exact path="/feessubscription" component={feessubscription} />
             <Route exact path="/feessubscriptionedit" component={feessubscriptionedit} />
             <Route exact path="/stakemaster" component={StakingMaster} />
             <Route exact path="/addstake" component={AddStake} />
             <Route exact path="/referals/:userId" component={ReferalTable} />
             <Route exact path="/userstake" component={UserStake} />
             <Route exact path="/updatePackage/:id" component={UpdateStake} />
             <Route exact path="/updatePackage" component={UpdateStake} />
             <Route exact path="/bankTransfers" component={BankTransferList} />
             <Route exact path="/editBankTransfer" component={EditBankTransferStatus} />
             
             <Route exact path="/addCoinPair" component={AddCoinPairs} /> 
             <Route exact path="/futureCoinPair" component={FutureCoinPairs} /> 


        </Switch>
    );
};

export default Routes;
