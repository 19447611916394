import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import moment from 'moment';

let data = [];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}
function dateFormatter(cell, row) {
    // const d=  moment(cell?.split(" ")[0]).format('MMM DD YYYY')
    const date = new Date(cell?.split(" ")[0])
    return `${('0' + date.getDate()).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`;
// const d = `${('0' + new Date(cell).getDate()).slice(-2)}/${('0' + (new Date(cell).getMonth() + 1)).slice(-2)}/${new Date(cell).getFullYear()}`
// console.log(d)
//     return d;
  }
export default class UserStake extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userStake: [],
            filteredList:[]
        }
    }

    UNSAFE_componentWillMount() {
        this.getStakingMaster();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
    }
    getStakingMaster = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.post(API_URL.USER_STAKE,{status:''})
            .then(res => {
                let userData = res.data.data
               
                let smList = res.data.data
                this.setState({
                    userStake: smList,filteredList:smList
                })
                // console.log("smList data:", smList)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }
    onFilter = (type,value) => {
        if (type === 'activeFilter') {
            const d = this.state.userStake.filter(e => e.active == value);
            console.log(d)
            this.setState({ filteredList: d })
        } else {
            const d = this.state.userStake.filter(e => e.status ==value);
            console.log(d)
            this.setState({ filteredList: d })
        }
    }
    buttonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.active) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="text-success"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Active
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="text-danger"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Inactive
                            </a>
                        </div>
                    </div>
                </div>
            )
        } 
    }
    statusFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.status == 1) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="text-primary"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Ongoing
                        </a>
                        </div>
                    </div>
                </div>
            )
        } else if(row.status == 2) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="text-info"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else{
            return(
                <div>-</div>
            )
        }
    }

    amountFormatters(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <a href="#" className="text-primary"
                            >
                                {(row?.usdPrice * row?.amount).toFixed(2)}
                        </a>
                        </div>
                    </div>
                </div>
            )
        
    }
    // cellEdit(cell, row, enumObject, rowIndex) {
    //     return (
    //         <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
    //             <div class="col-md-12">
    //                 <a href="#" className="btn btn-block btn-primary btn-xs"
    //                     onClick={() => {
    //                         let data = JSON.stringify(row)
    //                         this.props.history.push("/editwithdraw")
    //                     }}>
    //                     <i className="fa fa-edit " />
    //                 </a>
    //             </div>
    //         </div>
    //     )
    // }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="col-md-12">
                    {/* <a href=""
                        onClick={() => {
                            let data = row.userId
                            console.log("Withdraw", row.userId);
                            localStorage.setItem('emailData', data)
                            this.props.history.push("/edituser")
                        }}> */}
                    <a>
                        <p style={{ color: '#0069D9' }}>{row.userId}<br></br></p>
                    </a>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">User Stake</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> User Stake
                                                </CardHeader>
                                                <CardBody style={{position:'relative'}}>
                                                    <div className="position-absolute " style={{zIndex:2,display:'flex'}}>
                                                        <select name="statusFilter" id="statusFilter" className='form-control' onChange={(e)=>{this.onFilter('statusFilter',e.target.value)}}>
                                                            <option value={''} selected>Select</option>
                                                            <option value={1}>Ongoing</option>
                                                            <option value={2}>Completed</option>
                                                        </select>
                                                        <select name="activeFilter" id="activeFilter" className='form-control' onChange={(e)=>{this.onFilter('activeFilter',e.target.value)}}>
                                                        <option value={''} selected>Select</option>
                                                            <option value={1}>Active</option>
                                                            <option value={0}>Inactive</option>
                                                        </select>
                                                    </div>
                                                    <BootstrapTable data={this.state.filteredList} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                        <TableHeaderColumn
                                                            isKey
                                                            dataField='userId'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'23%'}
                                                        >USER ID</TableHeaderColumn>

                                                        <TableHeaderColumn
                                                            dataField='stakingPackageId'
                                                            thStyle={headerStyle}
                                                            width={'23%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >Staking PackageId</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='coinName' width={'18%'} thStyle={headerStyle}>Coin Name</TableHeaderColumn>
                                                
                                                        <TableHeaderColumn dataField='usdPrice' width={'15%'} thStyle={headerStyle}>USD Price</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='amount' width={'15%'} thStyle={headerStyle}>Amount</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='button'
                                                         dataFormat={this.amountFormatters.bind(this)} width={'15%'} thStyle={headerStyle}>Total($)</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='button'
                                                         dataFormat={this.statusFormatter.bind(this)}
                                                         width={'15%'}  thStyle={headerStyle}>STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='createdAt' width={'35%'} dataFormat={ dateFormatter } filter={ { type: 'DateFilter' } } thStyle={headerStyle}>Date & Time</TableHeaderColumn>

                                                        <TableHeaderColumn
                                                            dataField='active'
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'15%'}
                                                        >ACTIVE</TableHeaderColumn>
                                            
                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
