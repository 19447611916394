import React, { Component } from 'react';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import Loader from 'react-loader-spinner';
import Pagination from "react-js-pagination";
import { Label } from "reactstrap";
import './styleTable.scss';

var phoneNumber = '';
var email = '';
var kyc = ''
var page = 1;
const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

export default class KycList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activity: [],
            load: 0,
            count: 0,
            activePage: 1,
            searchField: '',
            searchBy: ''
        }
    }

    async UNSAFE_componentWillMount() {
        await axios.get(API_URL.KYC_COUNT)
            .then(res => {
                this.setState({ count: res.data.data })
                console.log("COunt:", res.data)
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        // await axios.get(API_URL.KYC_LIST)
        //     .then(res => {
        //         // let userData = res.data.data
        //        let userData = res.data.data.filter((d) => {
        //             return d !== null
        //         })
        //         console.log("Userdata: ", userData);
        //         let binddata = []
        //         Promise.all(
        //             userData.map(async (d, xid) => {
        //                 let internalbinddata = []
        //                 let user = {
        //                     "id": d.userId
        //                 }
        //                 return await axios.post(API_URL.USER_BY_ID, user)
        //                     .then(res => {
        //                        if(res.data.message === "IDDoesNotExists"){
        //                          phoneNumber = ''
        //                          email = ''
        //                          kyc = ''
        //                        } else {
        //                          phoneNumber = res.data.data.phoneNumber
        //                          email = res.data.data.emailId
        //                          kyc = res.data.data.kyc 
        //                     }
        //                     console.log(res)
        //                         var time = moment((d.timestamp)).format("DD-MM-YYYY h:mm:ss a");
        //                         internalbinddata["email"] = email
        //                         internalbinddata["phoneNumber"] = phoneNumber
        //                         internalbinddata["kyc"] = kyc
        //                         internalbinddata["status"] = d.status
        //                         internalbinddata["fullname"] = d.fullname
        //                         internalbinddata["country"] = d.country
        //                         internalbinddata["dateofbirth"] = d.dateofbirth
        //                         internalbinddata["acno"] = d.acno
        //                         internalbinddata["ifsc"] = d.ifsc
        //                         internalbinddata["address"] = d.address
        //                         internalbinddata["zipcode"] = d.zipcode
        //                         internalbinddata["doctype"] = d.doctype
        //                         internalbinddata["rejectionReason"] = d.rejectionReason
        //                         internalbinddata["docidno"] = d.docidno
        //                         internalbinddata["frontImage"] = d.frontImage
        //                         internalbinddata["backImage"] = d.backImage
        //                         internalbinddata["bankname"] = d.bankname
        //                         internalbinddata["passbook"] = d.passbook
        //                         internalbinddata["selfie"] = d.selfieImage
        //                         internalbinddata["ifsc"] = d.ifsc
        //                         internalbinddata["userId"] = d.userId
        //                         internalbinddata["_id"] = d._id
        //                         binddata[xid] = internalbinddata

        //                         d["email"] = email
        //                         d["phoneNumber"] = phoneNumber
        //                         d["kyc"] = kyc
        //                     })
        //             }))
        //             .then(data => {
        //                 console.log("inside promise", userData)
        //                 console.log("binddata", data)
        //                 this.setState({
        //                     activity: binddata,
        //                     load: 1,
        //                 })
        //             })
        //         // this.props.history.push('/activitylog')
        //     })
        //     .catch(error => {
        //         console.log("Error: ", error);
        //     })
        // this.getActivityLog();
    }

    async componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
        this.usersList();
    }

    usersList = async () => {
        console.log("Active Page: ", page)
        await axios.post(API_URL.KYC_LIST, {
            "pageNumber": page,
            "searchBy": this.state.searchBy,
            "searchField": this.state.searchField,
        })
            .then(res => {
                // let userData = res.data.data
                let userData = res.data.data.filter((d) => {
                    return d !== null
                })
                console.log("Userdata: ", userData);
                let binddata = []
                Promise.all(
                    userData.map(async (d, xid) => {
                        let internalbinddata = []
                        let user = {
                            "id": d.userId
                        }
                        return await axios.post(API_URL.USER_BY_ID, user)
                            .then(res => {
                                if (res.data.message === "IDDoesNotExists") {
                                    phoneNumber = ''
                                    email = ''
                                    kyc = ''
                                } else {
                                    phoneNumber = res.data.data.phoneNumber
                                    email = res.data.data.emailId
                                    kyc = res.data.data.kyc
                                }
                                console.log(res)
                                var time = moment((d.timestamp)).format("DD-MM-YYYY h:mm:ss a");
                                internalbinddata["email"] = email
                                internalbinddata["phoneNumber"] = phoneNumber
                                internalbinddata["kyc"] = kyc
                                internalbinddata["status"] = d.status
                                internalbinddata["fullname"] = d.fullname
                                internalbinddata["country"] = d.country
                                internalbinddata["dateofbirth"] = d.dateofbirth
                                internalbinddata["acno"] = d.acno
                                internalbinddata["ifsc"] = d.ifsc
                                internalbinddata["address"] = d.address
                                internalbinddata["zipcode"] = d.zipcode
                                internalbinddata["doctype"] = d.doctype
                                internalbinddata["rejectionReason"] = d.rejectionReason
                                internalbinddata["docidno"] = d.docidno
                                internalbinddata["frontImage"] = d.frontImage
                                internalbinddata["backImage"] = d.backImage
                                internalbinddata["bankname"] = d.bankname
                                internalbinddata["passbook"] = d.passbook
                                internalbinddata["selfie"] = d.selfieImage
                                internalbinddata["ifsc"] = d.ifsc
                                internalbinddata["userId"] = d.userId
                                internalbinddata["_id"] = d._id
                                binddata[xid] = internalbinddata

                                d["email"] = email
                                d["phoneNumber"] = phoneNumber
                                d["kyc"] = kyc
                            })
                    }))
                    .then(data => {
                        // console.log("inside promise", userData)
                        // console.log("binddata", data)
                        this.setState({
                            activity: binddata,
                            load: 1,
                        })
                    })
                // this.props.history.push('/activitylog')
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    handleSearch = async (e) => {
        e.preventDefault();
        let dataObj = {
            "searchBy": this.state.searchBy,
            "searchField": this.state.searchField,
            "pageNumber": this.state.page
        }
        await axios.post(API_URL.KYC_LIST, dataObj)
            .then(res => {
                // let userData = res.data.data
                let userData = res.data.data.filter((d) => {
                    return d !== null
                })
                console.log("Userdata: ", userData);
                let binddata = []
                Promise.all(
                    userData.map(async (d, xid) => {
                        let internalbinddata = []
                        let user = {
                            "id": d.userId
                        }
                        return await axios.post(API_URL.USER_BY_ID, user)
                            .then(res => {
                                if (res.data.message === "IDDoesNotExists") {
                                    phoneNumber = ''
                                    email = ''
                                    kyc = ''
                                } else {
                                    phoneNumber = res.data.data.phoneNumber
                                    email = res.data.data.emailId
                                    kyc = res.data.data.kyc
                                }
                                console.log(res)
                                var time = moment((d.timestamp)).format("DD-MM-YYYY h:mm:ss a");
                                internalbinddata["email"] = email
                                internalbinddata["phoneNumber"] = phoneNumber
                                internalbinddata["kyc"] = kyc
                                internalbinddata["status"] = d.status
                                internalbinddata["fullname"] = d.fullname
                                internalbinddata["country"] = d.country
                                internalbinddata["dateofbirth"] = d.dateofbirth
                                internalbinddata["acno"] = d.acno
                                internalbinddata["ifsc"] = d.ifsc
                                internalbinddata["address"] = d.address
                                internalbinddata["zipcode"] = d.zipcode
                                internalbinddata["doctype"] = d.doctype
                                internalbinddata["rejectionReason"] = d.rejectionReason
                                internalbinddata["docidno"] = d.docidno
                                internalbinddata["frontImage"] = d.frontImage
                                internalbinddata["backImage"] = d.backImage
                                internalbinddata["bankname"] = d.bankname
                                internalbinddata["passbook"] = d.passbook
                                internalbinddata["selfie"] = d.selfieImage
                                internalbinddata["ifsc"] = d.ifsc
                                internalbinddata["userId"] = d.userId
                                internalbinddata["_id"] = d._id
                                binddata[xid] = internalbinddata

                                d["email"] = email
                                d["phoneNumber"] = phoneNumber
                                d["kyc"] = kyc
                            })
                    }))
                    .then(data => {
                        // console.log("inside promise", userData)
                        // console.log("binddata", data)
                        this.setState({
                            activity: binddata,
                            load: 1,
                        })
                    })
                // this.props.history.push('/activitylog')
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                <div class="row">
                    <div class="col-md-6 text-center">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            onClick={() => {
                                let data = Object.assign({}, row)
                                data = JSON.stringify(data)
                                console.log("Row:", data);
                                localStorage.setItem('kycEdit', data)
                                this.props.history.push("/editKyc")
                            }}>
                            <i className="fa fa-eye " />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    kycButton(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.status == "Not Initiated" || row.status == '') {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-primary btn-xs"
                            >
                                Not Initiated
                        </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Approved") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Approved
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Pending" || row.status == "" || !row.status) {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-warning btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Pending
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        this.setState({ activePage: pageNumber });
        page = pageNumber
        this.usersList();
    }

    render() {
        console.log("Activity log data:", this.state.activity)
        console.log("Active age render: ", this.state.activePage);

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">KYC & Bank Details</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  KYC & Bank Details
                                                </CardHeader>
                                                <CardBody>
                                                    {this.state.load === 1 ?
                                                            <>
                                                             <form onSubmit={this.handleSearch}>
                                                                 <div className="row" >
                                                                <div className="col-md-6">
                                                                    <div className="row">
                                                                    <div className = "col-md-2.5">
                                                                    <Label>Search By: </Label>
                                                                    </div>
                                                                    <div className="col-md-8">
                                                                    <select
                                                                        className="form-control editor edit-text"
                                                                        value={this.state.searchBy}
                                                                        style={{width: '100%', height: '100%', fontSize:'13px'}}
                                                                        onChange={(e) => {
                                                                            this.setState({
                                                                                searchBy: e.target.value
                                                                            })
                                                                        }}>
                                                                        <option value="">-- select --</option>
                                                                        <option value="email">Email</option>
                                                                        <option value="fullName">Full Name</option>
                                                                        <option value="zipCode">Zip Code</option>
                                                                        <option value="status">Status</option>
                                                                        <option value="country">Country</option>
                                                                    </select>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input value={this.state.searchField} onChange={(e) => {
                                                                        this.setState({ searchField: e.target.value })
                                                                    }} placeholder="search"
                                                                        style={{ width: '60%', fontSize: '13px',marginRight: '20px', marginBottom: '10px', height: '70%', marginLeft: '-80px' }} />
                                                                    <button className="btn btn-primary" >Submit</button>
                                                                </div>
                                                            </div>
                                                            </form>

                                                            <BootstrapTable data={this.state.activity}
                                                                tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                                <TableHeaderColumn dataField='fullname' width={'15%'} thStyle={headerStyle} isKey>FULL NAME</TableHeaderColumn>
                                                                <TableHeaderColumn dataField='email' width={'20%'} thStyle={headerStyle}>EMAIL</TableHeaderColumn>
                                                                <TableHeaderColumn dataField='phoneNumber' thStyle={headerStyle}>PHONE NUMBER</TableHeaderColumn>
                                                                <TableHeaderColumn dataField='country' thStyle={headerStyle}>COUNTRY</TableHeaderColumn>
                                                                <TableHeaderColumn dataField='acno' thStyle={headerStyle}>A/C Number</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField='button'
                                                                    width={'15%'}
                                                                    tdStyle={{ whiteSpace: 'normal' }}
                                                                    dataFormat={this.kycButton.bind(this)}
                                                                    thStyle={headerStyle}
                                                                >KYC</TableHeaderColumn>
                                                                <TableHeaderColumn
                                                                    dataField='button'
                                                                    width={'15%'}
                                                                    tdStyle={{ whiteSpace: 'normal' }}
                                                                    dataFormat={this.buttonFormatter.bind(this)}
                                                                    thStyle={headerStyle}
                                                                >ACTION</TableHeaderColumn>
                                                            </BootstrapTable>

                                                            <div style={{ marginTop: '20px', float: 'right' }}>
                                                                <Pagination
                                                                    activePage={this.state.activePage}
                                                                    itemsCountPerPage={10}
                                                                    totalItemsCount={this.state.count}
                                                                    pageRangeDisplayed={5}
                                                                    onChange={this.handlePageChange.bind(this)}
                                                                />
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="text-center">
                                                            <Loader
                                                                type="Puff"
                                                                color="#343A40"
                                                                height={50}
                                                                width={50}
                                                            // timeout={10000}
                                                            />
                                                        </div>
                                                    }
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
