import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { API_URL } from "../../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';

let data = [];

const headerStyle={
    backgroundColor: '#ECF0F2',
    color : '#6F7B8A'
  }

export default class ActiveDeposietFund extends Component {
    constructor(props) {
        super(props);
        this.state = {
            depositFunds: [],
            transferdone: false
        }
    }

    UNSAFE_componentWillMount() {
        this.getDepositeHistory();
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
    }
    getDepositeHistory = async () => {
        console.log(this.state.Token);

        const headers = {
            'Token': this.state.Token,
        }
        await axios.get(API_URL.DEPOSITE_GET)
            .then(res => {
                let userData = res.data.data
                this.setState({
                    depositFunds: []
                })
                // userData.map((d) => {
                //     let userid = {
                //         "id" : d.userId
                //     }
                //      axios.post(API_URL.USER_BY_ID,
                //         userid,
                //         {
                //           headers: {
                //             'Content-Type': 'application/json',
                //           }
                //         })
                //         .then(res => {
                //           let emailOfUser = res.data.data.emailId
                //           d["emailId"] = emailOfUser
                //         })
                //         .catch(error => {
                //           console.log("Error: ", error);
                //         })
                // })
                console.log("all users: ", res.data.data);
                let userDepositeData = res.data.data
                userDepositeData.map((d) => {
                    if(d.status == "Completed"){
                        data.push(d)
                    }
                })
                console.log(data)
                this.setState({
                    depositFunds: userDepositeData
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    buttonFormatter(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);
        return (
            <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="row">
                    <div class="col-md-12">
                        <a href="#" className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Completed
                        </a>
                    </div>
                </div>
            </div>
        )
    }

    async transferTrx(id,coinname,walletaddress) {
        console.log("transferTrx",id,coinname,walletaddress);
        await axios.post(API_URL.TRNSFER_DEPOSIT_FUNDS, {
            "id": id
        })

            .then(async res => {
                console.log(res);
                if(res.data.message == "sentSuccessfuly"){
                    swal("CoinSentSuccessfuly", res.data.data, "success");

                }

                else if(res.data.message == "CoinAlreadyTransferred"){
                    swal("CoinAlreadyTransferred", "", "error");

                }

                else if(res.data.message == "CoinTransferMethodNotFound"){
                    swal("CoinTransferMethodNotFound", "", "error");

                }

                


                
                // var tokenTransfer = true;
                // if(coinname == "BTC") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "XLM") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "ETH") {
                //     await axios.get("http://35.154.143.36/eth.php?wallet="+walletaddress)
                //     .then(res => {
                        


                //     })
                //     tokenTransfer = false; 
                // } 
                // if(coinname == "TRX") {
                //     await axios.get("http://52.66.188.219/trx.php?wallet="+walletaddress)
                //     .then(res => {
                        


                //     })
                //     tokenTransfer = false; 
                // }
                // if(coinname == "BCH") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "ETC") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "XRP") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "LTC") {
                //     tokenTransfer = false; 
                // }
                // if(coinname == "DASH") {
                //     tokenTransfer = false; 
                // }

                // if(tokenTransfer == true) {

                //     await axios.get(API_URL + `ercToken/get/all1`)
                //         .then(async ress => {
                //             let tokenData = ress.data.data
                //             console.log("ERC20: ", tokenData);
                //             tokenData.map(async row => {
                            
                //                 if(row.tokenSymbol == coinname) {
                //                     console.log("ERC20: ", row.contractAddress);
                //                     // console.log("transferTrx","ERC Coind transfer")
                //                     await axios.get("http://35.154.143.36/token.php?wallet="+walletaddress+"&tokenaddress="+row.contractAddress)
                //                     .then(res => {
                                        


                //                     })
                //                 }
                //             })
                            
                //         })

                    
                // }
                // this.setState({transferdone: true})


            })

            // http://35.154.143.36/token.php?wallet=0xf6b65b3d0be8bfdfb7d521f6aebb99c9a24699cc&tokenaddress=0xe09394f8ba642430ed448ca20f342ec7aa1ba2e1

            // http://35.154.143.36/ethgas.php?wallet=0xf6b65b3d0be8bfdfb7d521f6aebb99c9a24699cc

            // http://35.154.143.36/eth.php?wallet=0xf6b65b3d0be8bfdfb7d521f6aebb99c9a24699cc

            // http://52.66.188.219/trx.php?wallet=TMCNp9yffKdUMGCdRptjrmXMwyRQaq8Grs
    }

    async transferGas(id,coinname,walletaddress) {
        console.log("transferTrx",id);
        await axios.get("http://35.154.143.36/ethgas.php?wallet="+walletaddress)
            .then(res => {
                


            })

    }
    transfer(cell, row, enumObject, rowIndex) {
        console.log("row value: ", row);

        var tokenTransfer = true;
        if(row.coinName == "BTC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "XLM") {
            tokenTransfer = false; 
        }
        if(row.coinName == "ETH") {
            tokenTransfer = false; 
        }
        if(row.coinName == "TRX") {
            tokenTransfer = false; 
        }
        if(row.coinName == "BCH") {
            tokenTransfer = false; 
        }
        if(row.coinName == "ETC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "XRP") {
            tokenTransfer = false; 
        }
        if(row.coinName == "LTC") {
            tokenTransfer = false; 
        }
        if(row.coinName == "DASH") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "signupbonus") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "refBonus") {
            tokenTransfer = false; 
        }

        if(row.walletAddress == "kycbonus") {
            tokenTransfer = false; 
        }
        
        return (
            <>
            {row.type === 'Internal Deposit'?<div></div> : <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '85%'}}>
                <div class="row">
                    <div class="col-md-12">
                        {!row.transferred &&
                        <>
                        {row.coinName == "TRX" &&
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Transfer {row.coinName}
                        </a>
                        }
                        </>
                        }
{!row.transferred &&
                        <>
                        {row.coinName == "ETH" &&
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            Transfer {row.coinName}
                        </a>
                        }
                        </>
    }

                        {tokenTransfer == true &&
                        <><a href="#" onClick={() => this.transferGas(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                        // onClick={() => {
                        //     console.log("Cell value: ", row)
                        //     this.deleteUser(row)
                        // }}
                        >
                        1. Send GAS
                        </a>
                        <a href="#" onClick={() => this.transferTrx(row._id,row.coinName,row.walletAddress)} className="btn btn-block btn-primary btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                            2. Transfer {row.coinName}
                        </a></> 
                        }
                    </div>
                </div>
            </div>}
            </>
        )
    }

    emailEdit(cell, row, enumObject, rowIndex) {
        return (
            <div style={{ flex: 1,justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%'}}>
                <div class="col-md-12">
                <a href=""
                            onClick={() => {
                                let data = row.userId
                                console.log("Withdraw", row.userId);
                                localStorage.setItem('emailData', data)
                                this.props.history.push("/edituser")
                            }}>
                                <p style={{color: '#0069D9'}}>{row.userId}</p>
                        </a>
                </div>
            </div>
        )
    }

    details(cell, row, enumObject, rowIndex) {
        if(row.coinName === "INR"){
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p><strong>Bank Name:</strong> {row.bankName}</p>
                            <p><strong>Account Type:</strong> {row.accountType}</p>
                            <p><strong>Account Number:</strong> {row.accountNumber}</p>
                            <p><strong>IFSC:</strong> {row.ifsc}</p>
                            <p><strong>Remark:</strong> {row.remark}</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, justifyContent: 'center', textAlign: 'center', alignItems: 'center', width: '65%' }}>
                    <div class="col-md-12">
                            <p>{row.walletAddress || row._id}</p>
                    </div>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{marginTop: '0px', backgroundColor: '#E5E9EC'}}>
                        <div className="content-header">
                            <div className="container-fluid">
                            <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Users</h1>
                                    </div>{/* /.col */}
                                    {/* <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Users</li>
                                        </ol>
                                    </div> */}
                                </div>{/* /.row */}
                                
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    {this.state.transferdone == true &&
                                <div class="alert alert-success">Transfer Request Submitted Successfully</div>
                                    }
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i>  Active Deposit Fund
                                                </CardHeader>
                                                <CardBody>
                                                    <BootstrapTable data={this.state.depositFunds} pagination search
                                                        tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn
                                                            isKey
                                                            dataField='button'
                                                            dataFormat={this.emailEdit.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'20%'}
                                                        >USER ID</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='coinName' width={'12%'} thStyle={headerStyle}>COIN NAME</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'
                                                            dataFormat={this.details.bind(this)}
                                                            thStyle={headerStyle}
                                                            width={'27%'}
                                                            // tdStyle={ { whiteSpace: 'normal' } }
                                                        >WALLET ADDRESS / DETAILS</TableHeaderColumn>
                                                        {/* <TableHeaderColumn dataField='walletAddress' width={'24%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn> */}
                                                        <TableHeaderColumn dataField='amount' width={'13%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                        <TableHeaderColumn
                                                            dataField='button'  width={'15%'}
                                                            dataFormat={this.buttonFormatter.bind(this)}
                                                            thStyle={headerStyle}
                                                        >STATUS</TableHeaderColumn>
                                                        <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE</TableHeaderColumn>

                                                        
                                                        <TableHeaderColumn
                                                            dataField='button' width={'15%'}
                                                            dataFormat={this.transfer.bind(this)}
                                                            thStyle={headerStyle}
                                                        >TRANSFER</TableHeaderColumn>


                                                    </BootstrapTable>
                                                </CardBody>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div>
        );
    }
}
