import React, { Component } from 'react';
// import { useParams } from 'react-router-dom';
import * as QueryString from "query-string"

import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL, BASE_URL } from "../../../config/api";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox';
import swal from 'sweetalert';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import './userTable.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

var id;
var editData = [];
var ercCoinData = [];

const headerStyle = {
    backgroundColor: '#ECF0F2',
    color: '#6F7B8A'
}

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class EditToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            userBalace: [],
            tokenValue: [],
            depositeHistory: [],
            withdrawalHistory: [],
            activationStatus: '',
            btnName: 'Lock',
            coinName: '',
            amountToUse: 0,
            adddepositamt: 0,
            coinBalance: [],
            userWalletAddress: "",
            userUserID: "",

        }
    }

    async componentDidMount() {
        
        // alert(QueryString.parse(this.props.location.search).id)

        id = QueryString.parse(this.props.location.search).id
        await axios.post(BASE_URL+"userInformation/getrbcbyid", { 
            "id": id
        })
            .then(async res => {
                    id = res.data.data.userId
                    this.setState({
                        userWalletAddress: res.data.data.walletAddress,
                        userUserID: res.data.data.userId,
                    })
                    await axios.post(API_URL.USER_BY_ID, { 
                        "id": res.data.data.userId
                    })
                        .then(resuser => {
                            console.log("user by id:", res);
                            this.setState({
                                tokenData: resuser.data.data
                            })
                        })
                        .catch(error => {
                            console.log("Error: ", error);
                        })
        })
        // this.tableData()
        await this.getUserBalance()
        this.getDepositeHistory()
        this.getWithdrawalHistory()
    }

    updateUserBalance = async () => {
        var obj = {}
        // obj['userId'] = id;
        if(this.state.adddepositamt > 0) {
        await axios.post(BASE_URL + "useraddrbcdeposit", {
            "userId": this.state.userUserID,
            "walletAddress": this.state.userWalletAddress,
            "amount": this.state.adddepositamt,
        })
            .then(async res => {
                if (res.data.message === "success") {
                    
                    await this.getUserBalance()
                    this.getDepositeHistory()
                    this.getWithdrawalHistory()

                    swal("Deposit Added Successfully", "", "success");
                    this.setState({
                        adddepositamt: 0
                    })
                } else {
                    swal("Unable to add", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
        } else {
            swal("Incorrect Amount", "", "error");
        }
    }





    getUserBalance = async () => {
        await axios.post(API_URL.USER_BALANCE, {
            "userId": id
        })
            .then(res => {
                // console.log("Edit user Balance:", res.data.data);
                ercCoinData = res.data.data

                this.setState({ coinBalance: res.data.data })
                
            })
            .catch(error => {
                console.log("Error: ", error);
            })

    }

    indexN(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }

    indexW(cell, row, enumObject, index) {
        return (<div>{index + 1}</div>)
    }
    getDepositeHistory = async () => {
        await axios.post(API_URL.DEPOSITE_HISTORY, {
            "userId": id
        })
            .then(res => {
                console.log("Deposite History:", res.data.data);
                this.setState({ depositeHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getWithdrawalHistory = async () => {
        await axios.post(API_URL.WITHDRAW_HISTORY, {
            "userId": id
        })
            .then(res => {
                // console.log("Withdrawal History:", res.data.data);
                this.setState({ withdrawalHistory: res.data.data })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }


    buttonFormatter(cell, row, enumObject, rowIndex) {
        // console.log("row value: ", row);
        if (row.status == "Pending") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-primary btn-xs"
                            >
                                Pending
                        </p>
                        </div>
                    </div>
                </div>
            )
        } else if (row.status == "Completed") {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-success btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Completed
                            </p>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ flex: 1, textAlign: 'center', alignItems: 'center', width: '100%' }}>
                    <div class="row">
                        <div class="col-md-12">
                            <p className="btn btn-block btn-danger btn-xs"
                            // onClick={() => {
                            //     console.log("Cell value: ", row)
                            //     this.deleteUser(row)
                            // }}
                            >
                                Rejected
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Add RBC Deposit</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                           
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="userName" style={label}>User Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="userName"
                                                            style={input}
                                                            value={this.state.tokenData.userName}
                                                            disabled />
                                                    </FormGroup>
                                                    

                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="email" style={label}>Email</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="email"
                                                            style={input}
                                                            value={this.state.tokenData.emailId}
                                                            disabled
                                                        />
                                                    </FormGroup>

                                                    

                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    Add Deposit
                                                </CardHeader>
                                                <div className="row" style={{ padding: '20px' }}>
                                                    
                                                <div className="col-md-3">
                                                    Current RBC Balance
                                                    <input className="form-control" value={this.state.coinBalance.RBC+" RBC"} disabled readOnly></input>
                                                </div>
                                                <div className="col-md-3">
                                                    Add RBC Deposit Amount
                                                    <input type="number" className="form-control"  value={this.state.adddepositamt} onChange={(e) => {
                                                                            this.setState({
                                                                                adddepositamt: e.target.value
                                                                            })
                                                                        }}></input>
                                                </div>
                                                
                                                <div className="col-md-6">
                                                     
                                                </div>
                                                <div className="clearfix"></div>
                                                <br></br>
                                                <div className="col-md-3">
                                                     
                                                </div>
                                                <div className="col-md-9">
<br></br>
                                                    <Button
                                                        onClick={this.updateUserBalance}
                                                        style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>Add RBC Deposit</Button>
                                                        
                                                        </div>

                                                </div>
                                            </Card>
                                        </Col>

                                    </Row>
                                </div>
                            </div>
                        </section>

                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    User History
                                                </CardHeader>
                                                <Tabs>
                                                    <TabList style={{
                                                        backgroundColor: '#D1DADE',
                                                        borderBottom: 0, color: '#777', height: '38px'
                                                    }}>
                                                        <Tab>Deposit</Tab>
                                                        <Tab>Withdrawal</Tab>
                                                    </TabList>

                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.depositeHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexN} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                    <TabPanel style={{ padding: '20px' }}>
                                                        <BootstrapTable data={this.state.withdrawalHistory} pagination
                                                            tableStyle={{ fontSize: 12, textAlign: 'center' }}>
                                                            <TableHeaderColumn dataField="any" dataFormat={this.indexW} thStyle={headerStyle} width={'7%'}>SR. No.</TableHeaderColumn>
                                                            <TableHeaderColumn isKey dataField='coinName' width={'10%'} thStyle={headerStyle}>COIN</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='amount' width={'10%'} thStyle={headerStyle}>AMOUNT</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='walletAddress' width={'20%'} thStyle={headerStyle}>WALLET ADDRESS</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='fees' width={'15%'} thStyle={headerStyle}>FEES</TableHeaderColumn>
                                                            <TableHeaderColumn dataField='date' width={'15%'} thStyle={headerStyle}>DATE &nbsp; TIME</TableHeaderColumn>
                                                            <TableHeaderColumn
                                                                dataField='button'
                                                                dataFormat={this.buttonFormatter.bind(this)}
                                                                thStyle={headerStyle}
                                                                width={'10%'}
                                                            >STATUS</TableHeaderColumn>
                                                        </BootstrapTable>
                                                    </TabPanel>
                                                </Tabs>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
