import React, { Component, useEffect, useState } from 'react';
import Header from '../../Component/Header'
// import Footer from '../Component/Footer';
import Menu from '../../Component/Menu';
import { API_URL } from "../../config/api";
import axios from "axios";
import swal from 'sweetalert';
// import {useSearchParams} from 'react-router-dom'
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";


const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export const UpdateStake= (props)=> {
   
    const [stakeDetails, setStakeDetalis] = useState()
const [packageName, setPackageName] = useState('');
const [lowerpriceLimit, setLowerpriceLimit] = useState('');
const [upperpriceLimit, setUpperpriceLimit] = useState('');
const [interestPercentage, setInterestPercentage] = useState('');
const [stakingPeriod, setStakingPeriod] = useState('');
const [ID, setID] = useState('')
// const {packageId} = '624daf70d3179752abe9f110'//useParams()
// const [searchParams, setSearchParams] = useSearchParams();
useEffect(() => {
    
   let packageId = localStorage.getItem('packageId')
    // alert(packageId)
    getStake(packageId)
    // console.log(props.match.params.stakeId,'props.match.params.stakeId')
}, []);


const getStake = async(packageId)=>{
    
    await axios.get(API_URL.GET_STAKING_MASTER).then(res=>{
        if(res.data.statusCode === 200 && res.data.data && res.data.data.length){
            
            const dt = res.data.data.find(e=>e._id == packageId)
            // setStakeDetalis(dt);
            setPackageName(dt.packageName)
            setLowerpriceLimit(dt.lowerpriceLimit)
            setUpperpriceLimit(dt.upperpriceLimit)
            setInterestPercentage(dt.interestPercentage)
            setStakingPeriod(dt.stakingPeriod)
            setID(dt._id)
        }
    }).catch(err=>{

    })
}
    const updateStaking = async () => {
        const a = localStorage.getItem('loginData')
        const adminDetails = a ? JSON.parse(a) : null;
        if (!adminDetails) {
            this.props.history.push("/login")
        }
        let stake = {
            packageName: packageName,
            lowerpriceLimit:+lowerpriceLimit,
            upperpriceLimit:+upperpriceLimit,
            interestPercentage:+interestPercentage,
            stakingPeriod:+stakingPeriod,
            Id:ID,
            userId:adminDetails._id
        }
        // let url = props.match.params.stakeId ? API_URL.ADD_STACKE : API_URL.UPDATE_STAKE
        await axios.post(API_URL.UPDATE_STAKE, stake)
            .then(res => {
                console.log("Balance:", res.data);
                if (res.data.statusCode === 200) {
                    swal(res.data.message, "", "success");
                    // cancleChange()
                    getStake(localStorage.getItem('packageId'))
                } else {
                    swal("Error", "", "error");
                }
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

   const cancleChange = () => {
                setPackageName('')
                setLowerpriceLimit('')
                setUpperpriceLimit('')
                setInterestPercentage('')
                setStakingPeriod('')
    }

        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Update Package</h1>
                                    </div>{/* /.col */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        <CardBody>

                                            <div className="row">
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label for="achn" style={label}>Package Name</Label>
                                                        <Input
                                                            type="text"
                                                            name="achn"
                                                            id="achn"
                                                            style={input}
                                                            value={packageName}
                                                            placeholder="Package Name"
                                                            onChange={e => {
                                                                setPackageName(e.target.value)
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label for="lowerpriceLimit" style={label}>Lower Price Limit</Label>
                                                        <Input
                                                            type="number"
                                                            name="lowerpriceLimit"
                                                            id="lowerpriceLimit"
                                                            style={input}
                                                            value={lowerpriceLimit}
                                                            placeholder="Lower Price Limit"
                                                            onChange={e => {
                                                                setLowerpriceLimit(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label for="upperpriceLimit" style={label}>Upper Price Limit</Label>
                                                        <Input
                                                            type="number"
                                                            name="upperpriceLimit"
                                                            id="upperpriceLimit"
                                                            value={upperpriceLimit}
                                                            style={input}
                                                            placeholder="Upper Price Limit"
                                                            onChange={e => {
                                                                setUpperpriceLimit(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label for="interestPercentage" style={label}>Interest Percentage</Label>
                                                        <Input
                                                            type="number"
                                                            name="interestPercentage"
                                                            id="interestPercentage"
                                                            style={input}
                                                            value={interestPercentage}
                                                            placeholder="Interest Percentage"
                                                            onChange={e => {
                                                                setInterestPercentage(e.target.value)
                                                            }} />
                                                    </FormGroup>
                                                </div>

                                                <div className="col-md-4">
                                                    <FormGroup>
                                                        <Label for="stakingPeriod" style={label}>Staking Period</Label>
                                                        <Input
                                                            type="number"
                                                            name="stakingPeriod"
                                                            id="stakingPeriod"
                                                            style={input}
                                                            value={stakingPeriod}
                                                            placeholder="Staking Period"
                                                            onChange={e => {
                                                                setStakingPeriod(e.target.value)
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className="col-md-4"></div>
                                          

                                                <Button
                                                    onClick={updateStaking}
                                                    style={{
                                                        marginRight: '20px', backgroundColor: "#F14D4D", borderColor: '#F14D4D',
                                                        marginLeft: '10px'
                                                    }}>Update</Button>
                                                <Button color="secondary"
                                                    onClick={cancleChange}>Cancel</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    
}
