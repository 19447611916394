import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import {
  Row,
  Card,
  CardBody,
  Input,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form
} from "reactstrap";
import { API_URL, BASE_URL } from "../../config/api";
import axios from "axios";
import swal from 'sweetalert';

const label = {
  fontSize: '14px',
  color: '#646089',
  fontWeight: '600'
}

const input = {
  fontSize: '13px',
  color: '#333'
}
export default class EditWithdramStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawData: [],
      status: '',
      finalcredit : '',
      currentInrBalance: 0,
    }
  }

  async componentDidMount() {
    let editData = localStorage.getItem('inrDepositEdit', '')
    editData = JSON.parse(editData)
    console.log("On edit page: ", editData);
    this.setState({
      withdrawData: editData,
      id: editData._id,
      status: editData.status,
      finalcredit: editData.amount
    })

    await axios.post(API_URL.USER_BALANCE, {
      "userId": editData.userId
  })
      .then(res => {
        console.log("currentinrbalance",res.data.data.INR)
          this.setState({ currentInrBalance: res.data.data.INR })
          
      })
      .catch(error => {
          console.log("Error: ", error);
      })

  }

  updateStatus = async () => {
    let userdata = {
      "id": this.state.id,
      "status": this.state.status,
      "finalcredit": Number(Number(this.state.finalcredit).toFixed(2))
    }
    let updateInrBalance = Number(Number(Number(this.state.currentInrBalance) + Number(this.state.finalcredit)).toFixed(2))
    console.log("currentinrbalance",updateInrBalance)
    await axios.post(BASE_URL+"depositehistoryINR/update/status",
      userdata,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        console.log("authenticate : ", res.data)
        swal("Updated Successfully!", "", "success");
        // if(res.data.message == "updatedSuccessfully") {
        //   swal("Updated Successfully!", "", "success");
        // } else{
        //   swal("Oops! Something went wrong.", "", "error");
        // }
      })
      .catch(error => {
        console.log("Error: ", error);
      })

    if(this.state.status == "Completed") {

      console.log("currentinrbalance2",updateInrBalance)
      let userdataBalance = {
        "userId": this.state.withdrawData.userId,
        "finalamount": Number(updateInrBalance)
      }
      await axios.post(BASE_URL+"depositehistoryINR/update/balance",
      userdataBalance,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {

          axios.post(API_URL.USER_BALANCE, {
          "userId": this.state.withdrawData.userId
          })
          .then(res => {
            console.log("currentinrbalance",res.data.data.INR)
              this.setState({ currentInrBalance: res.data.data.INR })
              
          })
          .catch(error => {
              console.log("Error: ", error);
          })
        // console.log("authenticate : ", res.data)
        // swal("Updated Successfully!", "", "success");
        // if(res.data.message == "updatedSuccessfully") {
        //   swal("Updated Successfully!", "", "success");
        // } else{
        //   swal("Oops! Something went wrong.", "", "error");
        // }
      })
      .catch(error => {
        console.log("Error: ", error);
      })
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="wrapper">
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Update INR Deposit</h1>
                  </div>{/* /.col */}
                  <div className="col-md-6">
                    <div className="row" style={{ float: 'right' }}>
                      <Button
                        color="primary"
                        onClick={() => this.props.history.push('/inrdepositefund')}
                        Style="float:right; margin: 20"> Back
                            </Button>
                    </div>
                  </div>
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>
            <section className="content">
              <div className="container-fluid">
                <Form>
                  <Card>
                    <div class="row">
                      <div class="col-md-6">
                        <CardTitle style={{ margin: 20 }}> Update INR Deposit Information</CardTitle>
                      </div>
                      <div class="col-md-6">

                      </div>
                    </div>
                    <CardBody>

                      <div class="row">
                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>USER ID</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.userId}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>COIN</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.coinName}

                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>AMOUNT</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.amount}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>DATE</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.date}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>BANK NAME</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.bankname}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>ACCOUNT NUMBER</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.acno}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>IFSC CODE</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.ifsc}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>REFERENCE ID</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.refid}
                              disabled />
                          </FormGroup>
                        </div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>REMARK</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.remark}
                              disabled />
                          </FormGroup>
                        </div>

<div class="col-md-6">
<FormGroup>
    <Label for="examplePassword" style={label}>STATUS</Label>
    <select
      className="form-control editor edit-text"
      value={this.state.status}
      style={input}
      onChange={e => {
        this.setState({
          status: e.target.value,
        })
      }}>
      <option value="Pending">Pending</option>
      <option value="Completed">Completed</option>
      <option value="Rejected">Rejected</option>
    </select>
  </FormGroup>
</div>

                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>FINAL CREDIT INR AMOUNT</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              onChange={e => {
                                this.setState({
                                  finalcredit: e.target.value,
                                })
                              }}
                              value={this.state.finalcredit}
                               />
                          </FormGroup>
                        </div>


                        <div class="col-md-12">
                          

                          <Button color="primary"
                            onClick={this.updateStatus}>VERIFY AND ADD BALANCE</Button>
                        </div>
                      
                      </div>
                    </CardBody>


                  </Card>

                </Form>

              </div>
            </section>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
