import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardTitle,
    FormGroup,
    Label,
    Button,
    Form
} from "reactstrap";
import { API_URL } from "../../config/api";
import axios from "axios";
import swal from 'sweetalert';
// import multer from 'multer';

// var storage = multer.diskStorage({
//     destination: (req, file, cb) => {
//     cb(null, 'public/coinicon')
//     },
//     filename: (req, file, cb) => {
//     cb(null, Date.now() + '-' + file.originalname)
//     }
//     });
//     const upload = multer({ storage })

var checkedBTC, checkedETH, checkedUSDT;

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

export default class AddToken extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenData: [],
            BTC: "0",
            INR: "0",
            ETH: "0",
            USDT: "0",
            // exchange: '',
            active: false,
            tokenName: '',
            tokenSymbol: '',
            tokenLogo: '',
            dfees: '',
            wfees: '',
            minwid: '',
            tfees: '',
            contractAddress: '',
            dActive: false,
            wActive: false,
            tActive: false,
            minAmtBTC: '',
            minAmtETH: '',
            minAmtUSDT: ''
        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
            this.props.history.push('/');
        }
    }

    pressButton = () => {
        var component = this
        var data = new FormData();
        data.append("photo", component.state.photo, component.state.name);
        var request = axios.post('http://localhost:3000/coinicon', data)
        request.then(function (response) {
            // you need to send data from server in response
            if (response.status == 200) {
                console.log('saved in db')
                component.state.documents.push(response.data.documents)
                // pushed document data in documents array
            }
        })
    }

    imageUpload = async (e) => {
        console.log("image: ", e.target.files[0].name)
        var uploadfile = e.target.files[0]
        const data = new FormData()
        data.append('file', uploadfile)
        data.append('name', this.state.tokenName)
        axios.post(API_URL.TOKEN_UPLAOD_ICON, data, { // receive two parameter endpoint url ,form data 
        })
            .then(res => { // then print response status
                console.log("File upload: ", res.data.data)
                let image = res.data.data
                this.setState({
                    tokenLogo: image
                })
            })
    }

    storeData = async () => {
        let tokenData = {
            tokenName: this.state.tokenName,
            tokenSymbol: this.state.tokenSymbol,
            tokenLogo: this.state.tokenLogo,
            dfees: this.state.dfees,
            wfees: this.state.wfees,
            minwid: this.state.minwid,
            tfees: this.state.tfees,
            BTC: this.state.BTC,
            INR: this.state.INR,
            ETH: this.state.ETH,
            exchange: this.state.exchange,
            USDT: this.state.USDT,
            active: this.state.active,
            contractAddress: this.state.contractAddress,
            dActive: this.state.dActive,
            wActive: this.state.wActive,
            tActive: this.state.tActive,
            minAmtBTC: this.state.minAmtBTC,
            minAmtETH: this.state.minAmtETH,
            minAmtUSDT: this.state.minAmtUSDT
        }
        console.log("Add data: ", tokenData);
        await axios.post(API_URL.ADD_TOKEN, tokenData)
            .then(res => {
                console.log(res);
                if (res.data.message == "addedSuccessfully") {
                    swal("Added Successfully", "", "success")
                } else {
                    swal("Error!", "", "error")
                }
            })
    }

    resetAll = async () => {
        this.setState({
            tokenName: '',
            tokenSymbol: '',
            tokenLogo: '',
            dfees: '',
            wfees: '',
            minwid: '',
            tfees: '',
            BTC: '0',
            INR: '0',
            ETH: '0',
            USDT: '0',
            active: false,
            contractAddress: '',
            dActive: false,
            wActive: false,
            tActive: false,
            minAmtBTC: '',
            exchange: '',
            minAmtETH: '',
            minAmtUSDT: ''
        })
    }
    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">Add Coins </h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right' }}>
                                            <Button
                                                color="primary"
                                                onClick={() => this.props.history.push('/erctokenlist')}
                                                Style="float:right; margin: 20"> View All Coins
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <Form>
                                    <Card>
                                        {/* <div class="row">
                                            <div class="col-md-6">
                                                <CardTitle style={{ margin: 20 }}> Edit ERC20 Token</CardTitle>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div> */}
                                        <CardBody>

                                            <div class="row">
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Token Name</Label>
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.tokenName}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    tokenName: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Contract Address</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.contractAddress}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    contractAddress: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <div class="row">
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Deposite Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.dfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            dfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal BTC</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalBTC}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalBTC: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Withdraw Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.wfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            wfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal ETH</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalETH}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalETH: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                    </div>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Status</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.active}
                                                            style={input}

                                                            onChange={(e) => {
                                                                var string = JSON.parse(e.target.value)
                                                                this.setState({
                                                                    active: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>


                                                </div>
                                                <div class="col-md-6">
                                                    <FormGroup>
                                                        <Label for="exampleEmail" style={label}>Token Symbol</Label>
                                                        <Input
                                                            type="email"
                                                            name="address"
                                                            id="exampleEmail"
                                                            value={this.state.tokenSymbol}
                                                            style={input}
                                                            onChange={(e) => {
                                                                this.setState({
                                                                    tokenSymbol: e.target.value,
                                                                })
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Token Logo</Label>
                                                        {/* <div className="form-control"> */}
                                                        <input class="form-control" name="photo"
                                                            accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                                            type="file" placeholder=" " required="" value=""
                                                        
                                                            // onChange={(e) => {
                                                            //     this.getPhoto(e)}}
                                                            onChange={(e) => {
                                                                this.imageUpload(e)
                                                                //    console.log(window.location.href+'image.png');
                                                            }}
                                                        />
                                                        {/* </div> */}

                                                    </FormGroup>
                                                    <div class="row">
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Minimum Withdraw in Token</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.minwid}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            minwid: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal USDT</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalUSDT}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalUSDT: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Treding Fees in %</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="password"
                                                                    id="examplePassword"
                                                                    value={this.state.tfees}
                                                                    style={input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            tfees: e.target.value,
                                                                        })
                                                                    }} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Amount INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minAmtINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minAmtINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Minimum Total INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.minTotalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    minTotalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Amount Decimal INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.amtDecimalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    amtDecimalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Price Decimal INR</Label>
                                                        <Input
                                                            type="email"
                                                            name="password"
                                                            id="examplePassword"
                                                            style={input}
                                                            value={this.state.priceDecimalINR}
                                                            onChange={e => {
                                                                this.setState({
                                                                    priceDecimalINR: e.target.value,
                                                                })
                                                            }} />
                                                    </FormGroup>
                                                        </div>
                                                    </div>
                                                    {/* <Label for="examplePassword" style={label}>Status</Label> */}
                                                    {/* <FormGroup>
                                                        <input type="checkbox"
                                                            value={this.state.active}
                                                            onChange={(e) => {
                                                                if (this.state.active === "0") {
                                                                    this.setState({
                                                                        active: "1",
                                                                    })
                                                                } else {
                                                                    this.setState({
                                                                        active: "0",
                                                                    })
                                                                }
                                                            }} />{' '}
                                                                Active
                                                        </FormGroup> */}
                                                    <Label for="examplePassword" style={label}>Select Pairs</Label>
                                                    <div className="row">
                                                        <div className="col-lg-2">
                                                            <FormGroup >
                                                                <input type="checkbox"
                                                                    value={this.state.BTC}
                                                                    onChange={(e) => {
                                                                        if (this.state.BTC === "0") {
                                                                            this.setState({
                                                                                BTC: "1",
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                BTC: "0",
                                                                            })
                                                                        }
                                                                    }} />{' '}
                                                                BTC
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup>
                                                                <input type="checkbox"
                                                                    value={this.state.ETH}
                                                                    onChange={(e) => {
                                                                        if (this.state.ETH === "0") {
                                                                            this.setState({
                                                                                ETH: "1",
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                ETH: "0",
                                                                            })
                                                                        }
                                                                    }} />{' '}
                                                                ETH
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup>
                                                                <input type="checkbox"
                                                                    value={this.state.USDT}
                                                                    onChange={(e) => {
                                                                        if (this.state.USDT === "0") {
                                                                            this.setState({
                                                                                USDT: "1",
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                USDT: "0",
                                                                            })
                                                                        }
                                                                    }} />{' '}
                                                                USDT
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-2">
                                                            <FormGroup >
                                                                <input type="checkbox"
                                                                    value={this.state.INR}
                                                                    onChange={(e) => {
                                                                        if (this.state.INR === "0") {
                                                                            this.setState({
                                                                                INR: "1",
                                                                            })
                                                                        } else {
                                                                            this.setState({
                                                                                INR: "0",
                                                                            })
                                                                        }
                                                                    }} />{' '}
                                                                INR
                                                            </FormGroup>
                                                        </div>
                                                       
                                                    </div>

                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Deposite</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.dActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    dActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                    
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Exchange</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.exchange}
                                                            style={input}
                                                            onChange={(e) => {
                                                                // console.log(e.target.value)
                                                                // var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    exchange: e.target.value
                                                                })
                                                            }}>
                                                            <option value="">Select</option>
                                                            <option value="Binance">Binance</option>
                                                            <option value="Bitallx">Bitallx</option>
                                                        </select>
                                                    </FormGroup>
                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Withdraw</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.wActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    wActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                   
                                                </div>
                                                <div className='col-md-4'>
                                                    <FormGroup>
                                                        <Label for="examplePassword" style={label}>Trade</Label>
                                                        <select
                                                            className="form-control editor edit-text"
                                                            value={this.state.tActive}
                                                            style={input}
                                                            onChange={(e) => {
                                                                console.log(e.target.value)
                                                                var string = JSON.parse(e.target.value)
                                                                // var val = (string === "true");
                                                                this.setState({
                                                                    tActive: string
                                                                })
                                                            }}>
                                                            <option value={true}>Active</option>
                                                            <option value={false}>Inactive</option>
                                                        </select>
                                                    </FormGroup>
                                                   
                                                </div>

                                                <Button
                                                    onClick={this.storeData}
                                                    style={{ marginRight: '20px', backgroundColor: "#099A8C", borderColor: '#099A8c' }}>ADD</Button>
                                                <Button color="secondary"
                                                    onClick={this.resetAll}>RESET</Button>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Form>
                            </div>
                        </section>
                    </div>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
