import React, { Component } from 'react';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import {
  Row,
  Card,
  CardBody,
  Input,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Form
} from "reactstrap";
import { API_URL } from "../../config/api";
import axios from "axios";
import swal from 'sweetalert';

const label = {
  fontSize: '14px',
  color: '#646089',
  fontWeight: '600'
}

const input = {
  fontSize: '13px',
  color: '#333'
}
export default class EditBankTransferStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      withdrawData: [],
      status: ''
    }
  }

  componentDidMount() {
    let editData = localStorage.getItem('editBankTransfer', '')
    editData = JSON.parse(editData)
    console.log("On edit page: ", editData);
    this.setState({
      withdrawData: editData,
      id: editData._id,
      status: editData.status
    })
  }

  updateStatus = async () => {
    let userdata = {
      "id": this.state.id,
      "status": this.state.status,
      "txhash": this.state.txhash
    }
    await axios.post(API_URL.WITHDRAW_STATUS_UPDATE,
      userdata,
      {
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(res => {
        // console.log("authenticate : ", res.data)
        // swal("Updated Successfully!", "", "success");
        if(res.data.statusCode == 200) {
          swal("Updated Successfully!", "", "success");
        } else{
          swal("Oops! Something went wrong.", "", "error");
        }
      })
      .catch(error => {
        console.log("Error: ", error);
      })
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="wrapper">
          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
            {/* Content Header (Page header) */}
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Edit Withdraw</h1>
                  </div>{/* /.col */}
                  <div className="col-md-6">
                    <div className="row" style={{ float: 'right' }}>
                      <Button
                        color="primary"
                        onClick={() => this.props.history.push('/withdrawlist')}
                        Style="float:right; margin: 20"> Back
                            </Button>
                    </div>
                  </div>
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>
            <section className="content">
              <div className="container-fluid">
                <Form>
                  <Card>
                    <div class="row">
                      <div class="col-md-6">
                        <CardTitle style={{ margin: 20 }}> Edit Withdraw Information</CardTitle>
                      </div>
                      <div class="col-md-6">

                      </div>
                    </div>
                    <CardBody>

                      <div class="row">
                        <div class="col-md-6">
                          <FormGroup>
                            <Label for="examplePassword" style={label}>USER ID</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.userId}
                              disabled />
                          </FormGroup>
                          <FormGroup>
                            <Label for="examplePassword" style={label}>NAME</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.userName}
                              disabled />
                          </FormGroup>
                          <FormGroup>
                            <Label for="examplePassword" style={label}>EMAIL ID</Label>
                            <Input
                              type="email"
                              name="email"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.emailId}
                              disabled />
                          </FormGroup>

                          <>
                            <FormGroup>
                              <Label for="examplePassword" style={label}>ACCOUNT NUMBER</Label>
                              <Input
                                type="email"
                                name="email"
                                id="examplePassword"
                                style={input}
                                value={this.state.withdrawData.accountNumber}
                                disabled />
                                 </FormGroup>
                                 <FormGroup>
                              <Label for="exampleEmail" style={label}>IFSC</Label>
                              <Input
                                type="email"
                                name="address"
                                id="exampleEmail"
                                style={input}
                                value={this.state.withdrawData.ifsc}
                                disabled />
                            </FormGroup> </>
                           
                          <FormGroup>
                            <Label for="examplePassword" style={label}>COIN</Label>
                            <Input
                              type="email"
                              name="password"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.coinName}
                              disabled />
                          </FormGroup>

                          <FormGroup>
                            <Label for="examplePassword" style={label}>STATUS</Label>
                            <select
                              className="form-control editor edit-text"
                              value={this.state.status}
                              style={input}
                              onChange={e => {
                                this.setState({
                                  status: e.target.value,
                                })
                              }}>
                              <option value="Pending">Pending</option>
                              <option value="Completed">Completed</option>
                              <option value="Rejected">Rejected</option>
                            </select>
                          </FormGroup>

                          <FormGroup>
                            <Label for="examplePassword" style={label}>
                              <>REFERENCE NUMBER</>
                              </Label>
                            <Input
                              type="text"
                              name="address"
                              id="exampleEmail"
                              style={input}
                              value={this.state.txhash}
                              onChange={e => {
                                this.setState({
                                  txhash: e.target.value,
                                })
                              }} />
                          </FormGroup>

                          <Button color="primary"
                            onClick={this.updateStatus}>SUBMIT</Button>
                        </div>
                        <div class="col-md-6">
                            <FormGroup>
                              <Label for="exampleEmail" style={label}>BANK NAME</Label>
                              <Input
                                type="email"
                                name="address"
                                id="exampleEmail"
                                style={input}
                                value={this.state.withdrawData.bankName}
                                disabled />
                            </FormGroup> 
                          

                          <>
                            <FormGroup>
                              <Label for="examplePassword" style={label}>ACCOUNT TYPE</Label>
                              <Input
                                type="email"
                                name="email"
                                id="examplePassword"
                                style={input}
                                value={this.state.withdrawData.accountType}
                                disabled />
                            </FormGroup>
                            <FormGroup>
                              <Label for="examplePassword" style={label}>REMARK</Label>
                              <Input
                                type="email"
                                name="email"
                                id="examplePassword"
                                style={input}
                                value={this.state.withdrawData.remark}
                                disabled />
                            </FormGroup>
                            </>

                          <FormGroup>
                            <Label for="examplePassword" style={label}>AMOUNT</Label>
                            <Input
                              type="number"
                              name="amount"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.amount}
                              disabled />
                          </FormGroup>
                          <FormGroup>
                            <Label for="examplePassword" style={label}>DATE</Label>
                            <Input
                              type="email"
                              name="password"
                              id="examplePassword"
                              style={input}
                              value={this.state.withdrawData.date}
                              disabled />
                          </FormGroup>
                        </div>
                      </div>
                    </CardBody>


                  </Card>

                </Form>

              </div>
            </section>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
