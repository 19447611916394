import React, { Component } from 'react';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import {
    Row,
    Card,
    CardBody,
    Input,
    CardHeader,
    FormGroup,
    Label,
    Button,
    Form,
    Col,
} from "reactstrap";
import { API_URL } from "../config/api";
import axios from "axios";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import swal from 'sweetalert';
import { keys } from '@material-ui/core/styles/createBreakpoints';

const label = {
    fontSize: '14px',
    color: '#646089',
    fontWeight: '600'
}

const input = {
    fontSize: '13px',
    color: '#333'
}

var id;
var editData = [];

export default class ViewMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            memberData: [],
            userId: '',
            ercToken: []

        }
    }

    componentDidMount() {
        let login = localStorage.getItem('adminLogin', '')
        console.log(login);
        if (login !== '1') {
          this.props.history.push('/');
        }
        let emailData = localStorage.getItem('emailData', '')
        console.log("Email: ", emailData);
        // id = emailData
        
        editData = localStorage.getItem('memberBalance', '')
        editData = JSON.parse(editData)
        console.log("On member balance: ", editData);
        id = editData.userId
        this.setState({
            // memberData: editData,
            // id: editData._id,
            userId: editData.userId,
            // email: editData.email,
        })
        this.getUserBalance();
        this.getUserById();
    }

    getUserBalance = async () => {
        await axios.get(API_URL.ERC20_TOKEN_LIST)
            .then(res => {
                console.log("ERC20 token:", res.data.data);
                let data = res.data.data
                this.setState({
                    ercToken: res.data.data,
                    ercLoading: true
                })
            })
            .catch(error => {
                console.log("Error: ", error);
            })
    }

    getUserById = async () => {
        console.log("Inside");
        let user = {
            "id": this.state.userId
        }
        await axios.post(API_URL.USER_BY_ID, {
            "id": id
        })
            .then((res) => {
                console.log("User Infor: ", res);
                if (res.data.message === "IDDoesNotExists") {
                    this.setState({ memberData: editData })
                } else {
                    let values = res.data.data
                    editData["name"] = values.userName
                    editData["email"] = values.emailId
                    editData["password"] = values.password
                    editData["phoneNumber"] = values.phoneNumber
                    console.log("User Infor: ", editData);
                    this.setState({ memberData: editData })
                }
            })
    }
    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="wrapper">
                    <div className="content-wrapper" style={{ marginTop: '0px', backgroundColor: '#E5E9EC' }}>
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1 className="m-0 text-dark">View Member Balance</h1>
                                    </div>{/* /.col */}
                                    <div className="col-md-6">
                                        <div className="row" style={{ float: 'right', marginRight: '10px' }}>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    localStorage.setItem('userData', '')
                                                    this.props.history.push('/memberbalance')
                                                }}
                                                Style="float:right; margin: 20"> Back
                                            </Button>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>
                        </div>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <Row>
                                        <Col >
                                            <Card>
                                                <CardHeader>
                                                    <i className="fa fa-align-justify" style={{
                                                        marginRight: 10
                                                    }}></i> View Member Balance
                                                </CardHeader>
                                                {this.state.memberData !== [] ?
                                                <CardBody>
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>User Name</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    id="examplePassword"
                                                                    style={input}
                                                                    value={this.state.memberData.name}
                                                                    disabled />
                                                            </FormGroup>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Email</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    id="examplePassword"
                                                                    style={input}
                                                                    value={this.state.memberData.email}
                                                                    disabled />
                                                            </FormGroup>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Password</Label>
                                                                <Input
                                                                    type="password"
                                                                    name="email"
                                                                    id="examplePassword"
                                                                    style={input}
                                                                    value={this.state.memberData.password}
                                                                    disabled />
                                                            </FormGroup>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <FormGroup>
                                                                <Label for="examplePassword" style={label}>Phone Number</Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    id="examplePassword"
                                                                    style={input}
                                                                    value={this.state.memberData.phoneNumber}
                                                                    disabled />
                                                            </FormGroup>
                                                        </div>
                                                        {this.state.ercToken.map((d) => (
                                                            d.active == true &&
                                                            <div class="col-md-3">
                                                                <FormGroup>
                                                                    <Label for="examplePassword" style={label}>{d.tokenSymbol}</Label>
                                                                    <Input
                                                                        type="email"
                                                                        name="email"
                                                                        id="examplePassword"
                                                                        style={input}
                                                                        value={this.state.memberData[d.tokenSymbol]}
                                                                        disabled />
                                                                </FormGroup>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div>
                                                        <Button
                                                            color="secondary"
                                                            onClick={() => {
                                                                localStorage.setItem('userData', '')
                                                                this.props.history.push('/memberbalance')
                                                            }}
                                                        > Back
                                            </Button>
                                                    </div>
                                                </CardBody> :
                                                <CardBody>
                                                    No data to display
                                                </CardBody>
    }
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        );
    }
}
